import {
  Box,
  Typography,
  Grid,
  LinearProgress,
  Skeleton,
  Stack,
} from "@mui/material";
import React from "react";
import { socialSpendCertificateToString } from "social-pro-common/interfaces/socialSpend";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

export type SocialSpendTargetAnalyticsLineItem = {
  id: string;
  targetType: string; // Assuming `SocialCommitmentTypeSpend` is a string
  targetDescription?: string;
  targetValue: number;
  monthReportedValue: number;
  totalReportedValue: number;
  progress: number; // Percentage from 0 to 100
};

type SocialSpendAnalyticsSummaryProps = {
  data?: SocialSpendTargetAnalyticsLineItem;
  loading: boolean;
};

const SocialSpendAnalyticsSummary: React.FC<
  SocialSpendAnalyticsSummaryProps
> = ({ data, loading }) => {
  if (loading) {
    return (
      <Box pt={2} mb={2}>
        <Skeleton variant="text" width="60%" height={32} />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Stack spacing={1}>
              <Skeleton variant="text" width="80%" />
              <Skeleton variant="text" width="50%" />
              <Skeleton variant="text" width="60%" />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={1}>
              <Skeleton variant="text" width="70%" />
              <Skeleton variant="text" width="50%" />
              <Skeleton variant="text" width="30%" />
              <Skeleton variant="rectangular" height={20} sx={{ mt: 1 }} />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    );
  }

  if (!data) {
    return (
      <Box pt={2} mb={2}>
        <Typography variant="body1" color="textSecondary">
          No data available.
        </Typography>
      </Box>
    );
  }
  const pc = Math.min(100, data.progress);

  return (
    <Box p={2} border={1} borderRadius={2} borderColor="grey.300" mb={2}>
      <Typography variant="h6" gutterBottom>
        {data.targetDescription || "Social Spend Target"}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <Typography variant="body1">
            <strong>Target Type:</strong>{" "}
            {data.targetDescription ||
              socialSpendCertificateToString(data.targetType as any)}
          </Typography>
          <Typography variant="body1">
            <strong>Target Value:</strong> ${data.targetValue.toLocaleString()}
          </Typography>
          <Typography variant="body1">
            <strong>Month Reported:</strong> $
            {data.monthReportedValue.toLocaleString()}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">
            <strong>Total Reported:</strong> $
            {data.totalReportedValue.toLocaleString()}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Progress:</strong> {formatDecimalPlaces(data.progress, 2)}%
          </Typography>
          <LinearProgress
            variant="determinate"
            value={pc}
            sx={{ borderRadius: 4, height: 8 }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SocialSpendAnalyticsSummary;
