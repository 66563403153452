import {
  Box,
  Typography,
  Grid,
  LinearProgress,
  Skeleton,
  Stack,
} from "@mui/material";
import React from "react";
import { LabourHoursAnalyticsForPackageModelLineItem } from "social-pro-common/interfaces/analytics";
import { labourHourCommitmentTypeToString } from "social-pro-common/interfaces/packageLabourHourCommitment";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

type LabourHourAnalyticsSummaryProps = {
  data?: LabourHoursAnalyticsForPackageModelLineItem;
  loading: boolean;
};

const LabourHourAnalyticsSummary: React.FC<LabourHourAnalyticsSummaryProps> = ({
  data,
  loading,
}) => {
  if (loading) {
    return (
      <Box p={2} mb={2}>
        <Skeleton variant="text" width="60%" height={32} />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Stack spacing={1}>
              <Skeleton variant="text" width="80%" />
              <Skeleton variant="text" width="50%" />
              <Skeleton variant="text" width="60%" />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={1}>
              <Skeleton variant="text" width="70%" />
              <Skeleton variant="text" width="50%" />
              <Skeleton variant="text" width="30%" />
              <Skeleton variant="rectangular" height={20} sx={{ mt: 1 }} />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    );
  }

  if (!data?.summary) {
    return (
      <Box p={2} mb={2}>
        <Typography variant="body1" color="textSecondary">
          No data available.
        </Typography>
      </Box>
    );
  }

  const committedHours = data.targets.reduce((acc, target) => {
    return acc + target.targetValue;
  }, 0);

  return (
    <Box pt={2} borderColor="grey.300" mb={2}>
      <Grid container alignItems="center">
        <Grid item xs={6}>
          <Stack spacing={1}>
            <Typography variant="body1" mt={1}>
              <strong>Target Type:</strong>{" "}
              {data.summary.targetDescription ||
                labourHourCommitmentTypeToString(data.summary.targetType)}
            </Typography>
            <Typography variant="body1">
              <strong>Committed:</strong> {formatDecimalPlaces(committedHours)}{" "}
              Hours
            </Typography>
            <Typography variant="body1">
              <strong>Month Reported:</strong>{" "}
              {formatDecimalPlaces(data.summary.monthReportedValue, 0)} Hours
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={1}>
            <Typography variant="body1">
              <strong>Total Reported:</strong>{" "}
              {formatDecimalPlaces(data.summary.totalReportedValue, 0)} Hours
            </Typography>
            <Typography variant="body1">
              <strong>Actual:</strong>{" "}
              {formatDecimalPlaces(data.summary.actual)} Hours
            </Typography>
            <Typography variant="body1">
              <strong>Progress:</strong>{" "}
              {formatDecimalPlaces(data.summary.progress)}%
            </Typography>
            <LinearProgress
              variant="determinate"
              value={Math.min(data.summary.progress, 100)}
              sx={{ borderRadius: 4, height: 8 }}
            />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LabourHourAnalyticsSummary;
