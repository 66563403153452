import { useProjectContext } from "@hooks/context/useProjectContext";
import { useLabourHourAnalytics } from "@hooks/crud/analytics/useLabourHourAnalytics";
import DownloadIcon from "@mui/icons-material/Download";
import { Grid } from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { PageHeader } from "@stories/molecules/PageHeader/PageHeader";
import { AnalyticsHoursLevel1Table } from "@stories/organisms/AnalyticsHoursTables/AnalyticsHoursLevel1Table";
import * as RouteHelper from "@utils/routes";
import { writeXlsxLabourHourTargets } from "@utils/xlsx/labourHours";
import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { LabourHourTargetAnalyticsLineItem } from "social-pro-common/interfaces/analytics";
// import { SocialCommitmentTypeHour } from "social-pro-common/interfaces/packageLabourHourCommitment";

// const mockRows = [
//   {
//     actual: 999,
//     id: "123",
//     monthReportedValue: 999,
//     progress: 50,
//     targetDescription: "Target Description",
//     targetType: SocialCommitmentTypeHour.DefaultHour,
//     targetValue: 999,
//     totalReportedValue: 999,
//   },
// ] as LabourHourTargetAnalyticsLineItem[];

export const AnalyticsHoursLevel1 = () => {
  const navigate = useNavigate();
  const { reportId } = useParams();

  if (!reportId) {
    throw Error("No report id");
  }
  const {
    contractorPackage,
    isPrimaryPackage,
    isProjectLoading,
    selectedProject,
  } = useProjectContext();

  const { isAnalysisLoading, labourHoursAnalytics } = useLabourHourAnalytics(
    reportId,
    selectedProject?.id,
    contractorPackage?.id,
  );

  const onLabourHourTargetChange = useCallback(
    (targetId: string) => {
      if (selectedProject && contractorPackage && reportId) {
        if (isPrimaryPackage) {
          navigate(
            RouteHelper.analyticsLabourHoursSubcontractors(
              selectedProject.id,
              contractorPackage.id,
              targetId,
              reportId,
            ),
          );
        } else {
          navigate(
            RouteHelper.analyticsViewLabourHours(
              selectedProject.id,
              contractorPackage.id,
              contractorPackage.id,
              reportId,
              targetId,
              "package",
            ),
          );
        }
      }
    },
    [selectedProject, contractorPackage, reportId, isPrimaryPackage],
  );

  const loading = isProjectLoading || isAnalysisLoading;

  return (
    <>
      <Grid
        container
        sx={{
          margin: "0",
          marginBottom: "0",
          width: "100%",
        }}
      >
        <PageHeader
          loading={loading}
          title="Labour Hours"
          subTitle={
            isPrimaryPackage ? "Project Analytics" : "Package Analytics"
          }
        />

        <Grid
          container
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "end",
            padding: "20px 40px 10px",
          }}
        >
          <StyledButton
            data-test-id="export-targets-button"
            className="topBarBtn"
            loading={loading}
            disabled={
              loading ||
              !labourHoursAnalytics ||
              !labourHoursAnalytics?.targets.length
            }
            variant="contained"
            onClick={() => {
              if (labourHoursAnalytics) {
                writeXlsxLabourHourTargets(labourHoursAnalytics.targets);
              }
            }}
            startIcon={<DownloadIcon />}
          >
            Export Targets
          </StyledButton>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          className="analytic-table glb-table-sty"
          sx={{ padding: "5px 40px !important" }}
        >
          <AnalyticsHoursLevel1Table
            loading={loading}
            labourHourAnalytics={labourHoursAnalytics}
            onLabourHourTargetChange={onLabourHourTargetChange}
          />
        </Grid>
      </Grid>
    </>
  );
};
