import { useProjectContext } from "@hooks/context/useProjectContext";
import { useSocialSpendExport } from "@hooks/crud/downloadFiles/useSocialSpendExport";
import { useSocialSpendForTargets } from "@hooks/crud/socialSpend/useSocialSpendForTargets";
import DownloadIcon from "@mui/icons-material/Download";
import { Grid, Stack } from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { SortInfo } from "@stories/atoms/TableHeader/TableHeader";
import { PageHeader } from "@stories/molecules/PageHeader/PageHeader";
import { PdfDownlodDialog } from "@stories/molecules/PdfDownlodDialog/PdfDownlodDialog";
import { AnalyticsSpendLevel3Table } from "@stories/organisms/AnalyticsSpendTables/AnalyticsSpendLevel3Table";
import { Breadcrumbs } from "@stories/organisms/Breadcrumbs/Breadcrumbs";
import * as RouteHelper from "@utils/routes";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { socialSpendCommitmentTypeToString } from "social-pro-common/interfaces/packageSocialSpendCommitment";

export const AnalyticsSpendLevel3 = () => {
  const { mode, projectId, reportId, subPackageId, targetId } = useParams();
  if (!targetId) {
    throw Error("No target id");
  }
  if (!reportId) {
    throw Error("No report id");
  }
  if (!projectId) {
    throw Error("No project id");
  }
  if (!subPackageId) {
    throw Error("No contractor package id");
  }

  const [sortInfo, setSortInfo] = useState<SortInfo>({
    index: 0,
    order: "asc",
    orderBy: 0,
    page: 0,
  });

  const handleRequestSort = (newInfo: SortInfo) => {
    const isAsc =
      sortInfo.orderBy === newInfo.orderBy && sortInfo.order === "asc";
    setSortInfo({
      ...sortInfo,
      ...newInfo,
      order: isAsc ? "desc" : "asc",
    });
  };

  const {
    contractorPackage,
    isPrimaryPackage,
    isProjectLoading,
    selectedProject,
  } = useProjectContext();

  const { downloadProgress, downloadStep, getSocialSpendExcel } =
    useSocialSpendExport();

  const {
    isLoadingMore,
    isSocialSpendLoading,
    socialSpendCount,
    socialSpends,
  } = useSocialSpendForTargets(
    projectId,
    targetId,
    subPackageId,
    sortInfo,
    mode,
  );

  const loading = isProjectLoading || isSocialSpendLoading;

  const selectedTarget = selectedProject?.commitmentsSpend.find(
    (c) => c.id === targetId,
  );
  const matchingTarget = selectedProject?.commitmentsSpend.find(
    (target) => target.id === targetId,
  );
  const uniqueName = socialSpends
    .map((t) => t.organisationName)
    .filter((item, index, self) => index === self.findIndex((t) => t === item));

  const displayText =
    mode == "all" ? "All" : uniqueName.length ? uniqueName[0] : "";

  const breadcrumbData = [
    {
      href: RouteHelper.analyticsSpend(
        selectedProject?.id || "",
        contractorPackage?.id || "",
        reportId,
      ),
      label: "Social Spend",
    },
    {
      href: RouteHelper.analyticsSpendSubcontractors(
        selectedProject?.id || "",
        contractorPackage?.id || "",
        targetId || "",
        reportId,
      ),
      label: matchingTarget
        ? matchingTarget.targetDescription ||
          socialSpendCommitmentTypeToString(matchingTarget.targetName)
        : "",
    },
    {
      href: RouteHelper.analyticsViewSpend(
        selectedProject?.id || "",
        contractorPackage?.id || "",
        subPackageId || "",
        reportId,
        targetId,
        "package",
      ),
      label: displayText,
    },
  ];
  return (
    <>
      <Grid
        container
        sx={{
          margin: "0",
          marginBottom: "0",
          width: "100%",
        }}
      >
        <PageHeader
          loading={loading}
          title={`Spend - ${
            selectedTarget
              ? selectedTarget.targetDescription ||
                socialSpendCommitmentTypeToString(selectedTarget.targetName)
              : ""
          }`}
          subTitle={
            isPrimaryPackage ? "Project Analytics" : "Package Analytics"
          }
        />

        <Grid
          item
          md={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px 40px 10px",
          }}
        >
          <Breadcrumbs loading={loading} data={breadcrumbData} />
          <Stack
            direction="row"
            spacing={2}
            alignContent={"end"}
            justifyContent={"end"}
          >
            <StyledButton
              data-test-id="export-targets-button"
              className="topBarBtn"
              loading={loading}
              disabled={loading || !socialSpends || !socialSpends.length}
              variant="contained"
              onClick={() => {
                //TODO: Fix this to remove redundant data
                if (projectId && subPackageId && reportId && targetId) {
                  getSocialSpendExcel(
                    projectId,
                    targetId,
                    projectId === subPackageId ? undefined : subPackageId,
                  );
                }
              }}
              startIcon={<DownloadIcon />}
            >
              Export Records
            </StyledButton>
          </Stack>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        className="analytic-table glb-table-sty"
        sx={{ padding: "0px 40px !important" }}
      >
        <AnalyticsSpendLevel3Table
          loading={loading}
          socialSpends={socialSpends}
          socialSpendCount={socialSpendCount}
          isLoadingMore={isLoadingMore}
          sortInfo={sortInfo}
          handleRequestSort={handleRequestSort}
          mode={mode}
          packageId={subPackageId}
          targetId={targetId}
        />
      </Grid>
      <PdfDownlodDialog
        downloadStep={downloadStep}
        total={downloadProgress}
        title={"Social Spend Export"}
      />
    </>
  );
};
