import { downloadFile } from "@hooks/utils/useUpload";
import { Box, Button, Grid } from "@mui/material";
import { SortInfo } from "@stories/atoms/TableHeader/TableHeader";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { useCallback, useRef, useState } from "react";
import { DEFAULT_SOCIAL_SPEND_LIMIT } from "social-pro-common/entities/socialSpend";
import { SocialSpendLineItem } from "social-pro-common/interfaces/socialSpend";
import { formatDecimalPlaces } from "social-pro-common/utils/number";
import { getDocumentName } from "social-pro-common/utils/string";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Table } from "../Table/Table";

interface AnalyticsSpendLevel3TableProps {
  loading: boolean;
  socialSpends: SocialSpendLineItem[];
  socialSpendCount: number;
  sortInfo: SortInfo;
  isLoadingMore: boolean;
  mode?: string;
  targetId: string;
  packageId?: string;
  handleRequestSort: (sortInfo: SortInfo) => void;
}
interface ActionCellProps extends CustomCellRendererProps {
  onDownload: (uri: string, spendName: string) => Promise<void>;
}

const ActionCell = (props: ActionCellProps) => {
  const { data, onDownload } = props;

  function handleClick() {
    onDownload(
      data.uri,
      getDocumentName(
        `${data.supplier}-${data.transactionDate.toLocaleDateString("en-GB")}`,
        data.uri,
      ),
    );
  }

  return (
    <Button
      onClick={handleClick}
      variant="outlined"
      size="small"
      disabled={!data.url || data.url.length === 0}
    >
      Documents
    </Button>
  );
};

export const AnalyticsSpendLevel3Table = ({
  handleRequestSort,
  isLoadingMore,
  loading,
  socialSpendCount,
  socialSpends,
  sortInfo,
}: AnalyticsSpendLevel3TableProps) => {
  const observer = useRef<IntersectionObserver | null>(null);
  const [_, setIsDownloading] = useState(false);
  const onDownload = async (uri: string, spendName: string) => {
    setIsDownloading(true);
    await downloadFile(uri, spendName);
    setIsDownloading(false);
  };
  // Callback for observing the trigger element
  const lastElementRef = useCallback(
    (node: any) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          handleRequestSort({ ...sortInfo, page: sortInfo.page + 1 }); // Increment page on intersection
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, sortInfo],
  );

  function AnalyticsSocialSpendTable() {
    const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
      {
        field: "transactionDate",
        flex: 2,
        headerName: "Transaction Date",
        valueFormatter: (params) => params.value.toLocaleDateString(),
      },
      {
        field: "organisationName",
        flex: 3,
        headerName: "Subcontractor Name",
      },
      {
        field: "supplier",
        flex: 3,
        headerName: "Supplier",
      },
      {
        cellStyle: {
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }, // Center the button
        field: "invoiceValue",
        flex: 2,
        headerClass: "centered-table-header",
        headerName: "Amount",
        valueFormatter: (params) => `$${formatDecimalPlaces(params.value, 0)}`,
      },
      {
        cellRenderer: ActionCell,
        cellRendererParams: {
          onDownload,
        },
        cellStyle: {
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }, // Center the button
        field: "action",
        headerClass: "centered-table-header",
        headerName: "Actions",
      },
    ]);

    return (
      <Table<SocialSpendLineItem>
        columnDefs={colDefs}
        loading={loading}
        data={socialSpends}
        searchKeys={["organisationName", "supplier"]}
      />
    );
  }

  return (
    <>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          margin: "0 4px",
          padding: "20px 0 !important",
        }}
      >
        <AnalyticsSocialSpendTable />
      </Grid>
      {socialSpendCount > DEFAULT_SOCIAL_SPEND_LIMIT &&
      socialSpends.length < socialSpendCount ? (
        <Box
          ref={lastElementRef}
          style={{ backgroundColor: "transparent", height: "20px" }}
        />
      ) : null}
    </>
  );
};
