import { useProjectContext } from "@hooks/context/useProjectContext";
import { useSocialSpendAnalyticsForPackages } from "@hooks/crud/analytics/useSocialSpendAnalyticsForPackage";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Grid } from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { PageHeader } from "@stories/molecules/PageHeader/PageHeader";
import { AnalyticsSpendTableLevel2Table } from "@stories/organisms/AnalyticsSpendTables/AnalyticsSpendLevel2Table";
import { Breadcrumbs } from "@stories/organisms/Breadcrumbs/Breadcrumbs";
import * as RouteHelper from "@utils/routes";
import { useCallback, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { socialSpendCommitmentTypeToString } from "social-pro-common/interfaces/packageSocialSpendCommitment";

export const AnalyticsSpendLevel2 = () => {
  const { reportId, targetId } = useParams();
  if (!reportId) {
    throw Error("No report id");
  }
  if (!targetId) {
    throw Error("No target id");
  }
  const navigate = useNavigate();

  const {
    contractorPackage,
    isPrimaryPackage,
    isProjectLoading,
    selectedProject,
  } = useProjectContext();

  const { isAnalysisLoading, socialSpendAnalytics } =
    useSocialSpendAnalyticsForPackages(
      reportId,
      selectedProject?.id,
      contractorPackage?.id,
      targetId,
    );

  const loading = isProjectLoading || isAnalysisLoading;

  if (socialSpendAnalytics) {
    socialSpendAnalytics.targets = socialSpendAnalytics.targets.sort((a, b) => {
      if (a.packageNumber > b.packageNumber) {
        return 1;
      }
      return -1;
    });
  }

  const selectedProjectRef = useRef(selectedProject);
  const selectedPackageRef = useRef(contractorPackage);

  //Ag Table is not updating when the selectedProject changes
  //This is a workaround to make sure the table updates when the selectedProject changes
  useEffect(() => {
    selectedProjectRef.current = selectedProject;
    selectedPackageRef.current = contractorPackage;
  }, [selectedProject, contractorPackage]);

  const viewSubContractorDetails = useCallback(
    (subPackage: ContractorPackageLineItem) => {
      console.log("View package details", subPackage);
      const currentProject = selectedProjectRef.current;
      const currentContractorPackage = selectedPackageRef.current;
      if (currentProject && currentContractorPackage) {
        navigate(
          RouteHelper.analyticsViewSpend(
            currentProject.id,
            currentContractorPackage.id,
            subPackage.id,
            reportId,
            targetId,
            "package",
          ),
        );
      }
    },
    [selectedProject, contractorPackage],
  );

  const breadcrumbData = [
    {
      href: RouteHelper.analyticsSpend(
        selectedProject?.id || "",
        contractorPackage?.id || "",
        reportId,
      ),
      label: "Social Spend",
    },
    {
      href: RouteHelper.analyticsSpendSubcontractors(
        selectedProject?.id || "",
        contractorPackage?.id || "",
        targetId || "",
        reportId,
      ),
      label: socialSpendAnalytics
        ? socialSpendAnalytics?.summary.targetDescription ||
          socialSpendCommitmentTypeToString(
            socialSpendAnalytics?.summary.targetType,
          )
        : "",
    },
  ];

  return (
    <Grid
      container
      sx={{
        margin: "0",
        marginBottom: "0",
        width: "100%",
      }}
    >
      <PageHeader
        loading={loading}
        title="Social Spend"
        subTitle={isPrimaryPackage ? "Project Analytics" : "Package Analytics"}
      />

      <Grid
        item
        md={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "20px 40px 10px",
        }}
      >
        <Breadcrumbs loading={loading} data={breadcrumbData} />
        <StyledButton
          data-test-id="export-targets-button"
          className="topBarBtn"
          sx={{
            justifySelf: "end",
          }}
          loading={loading}
          disabled={loading}
          variant="contained"
          onClick={() => {
            navigate(
              RouteHelper.analyticsViewSpend(
                selectedProject?.id || "",
                contractorPackage?.id || "",
                reportId,
                targetId,
                "all",
              ),
            );
          }}
          startIcon={<RemoveRedEyeIcon />}
        >
          View All
        </StyledButton>
      </Grid>

      <Grid
        item
        xs={12}
        md={12}
        className="contract-table"
        sx={{
          margin: "0 40px",
          padding: "5px 0 40px !important",
        }}
      >
        <AnalyticsSpendTableLevel2Table
          loading={loading}
          targetId={targetId}
          socialSpendAnalytics={socialSpendAnalytics}
          viewPackageDetails={viewSubContractorDetails}
        />
      </Grid>
    </Grid>
  );
};
