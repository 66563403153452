import { useDebounce } from "@hooks/utils/useDebounce";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, styled, TextField } from "@mui/material";
import { useEffect, useState } from "react";

const StyledTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    height: 40px;
    padding-left: 6px;
    width: 188px;
    & fieldset {
      border-radius: 6px;
    }

    &:hover fieldset {
      border-color: #888;
    }

    &.Mui-focused fieldset {
      border-color: #008ce6;
    }
  }

  & .MuiInputBase-input {
    font-size: 14px;
  }
`;

interface AgTableSearchProps {
  loading: boolean;
  onChange: (param: string) => void;
}

export function SearchFilter({ loading, onChange }: AgTableSearchProps) {
  const [fieldValue, setFieldValue] = useState("");

  const debouncedValue = useDebounce<string>(fieldValue, 300);

  useEffect(() => {
    onChange(debouncedValue);
  }, [debouncedValue, onChange]);

  return (
    <StyledTextField
      size="small"
      fullWidth
      disabled={loading}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        setFieldValue(event.target.value)
      }
      placeholder="Search"
      value={fieldValue}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
}
