import { Button } from "@mui/material";
import { ProgressCell, Table } from "@stories/organisms/Table/Table";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { useState } from "react";
import {
  LabourHoursAnalyticsModelLineItem,
  LabourHourTargetAnalyticsLineItem,
} from "social-pro-common/interfaces/analytics";
import { labourHourCommitmentTypeToString } from "social-pro-common/interfaces/packageLabourHourCommitment";
import { formatDecimalPlaces } from "social-pro-common/utils/number";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

interface AnalyticsHoursLevel1TableProps {
  loading: boolean;
  labourHourAnalytics?: LabourHoursAnalyticsModelLineItem;
  onLabourHourTargetChange: (targetId: string) => void;
}

interface ActionCellProps extends CustomCellRendererProps {
  onLabourHourTargetChange: (arg: string) => void;
}

const ActionCell = (props: ActionCellProps) => {
  const {
    data: { id },
    onLabourHourTargetChange,
  } = props;

  function handleClick() {
    onLabourHourTargetChange(id);
  }

  return (
    <Button onClick={handleClick} variant="outlined" size="small">
      View
    </Button>
  );
};

export const AnalyticsHoursLevel1Table = ({
  labourHourAnalytics,
  loading,
  // loading,
  onLabourHourTargetChange,
}: AnalyticsHoursLevel1TableProps) => {
  function AnalyticsLabourHoursTable() {
    const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
      {
        field: "targetType",
        flex: 3,
        headerName: "Target Type",
        valueGetter: (params: any) => {
          const description = params.data.targetDescription; // Default to empty if null/undefined
          const type = params.data.targetType
            ? labourHourCommitmentTypeToString(params.data.targetType)
            : "";
          return description || type; // Prefer description, fallback to type
        },
      },
      {
        cellStyle: { textAlign: "center" },
        field: "targetValue",
        flex: 2,
        headerClass: "centered-table-header",
        headerName: "Target (Hours)",
        valueFormatter: (params) => `${formatDecimalPlaces(params.value, 0)}`,
      },
      {
        cellStyle: { textAlign: "center" },
        field: "totalReportedValue",
        flex: 2,
        headerClass: "centered-table-header",
        headerName: "Hours to date (Hours)",
        valueFormatter: (params) => `${formatDecimalPlaces(params.value, 0)}`,
      },
      {
        cellStyle: { textAlign: "center" },
        field: "monthReportedValue",
        flex: 2,
        headerClass: "centered-table-header",
        headerName: "This Month (Hours)",
        valueFormatter: (params) => `${formatDecimalPlaces(params.value, 0)}`,
      },
      {
        cellStyle: { textAlign: "center" },
        field: "actual",
        flex: 2,
        headerClass: "centered-table-header",
        headerName: "% of Total Hours",
        valueFormatter: (params) => `${formatDecimalPlaces(params.value, 0)}%`,
      },
      {
        cellRenderer: ProgressCell,
        field: "progress",
        flex: 2,
        headerClass: "centered-table-header",
        headerName: "Progress to Target",
      },
      {
        cellRenderer: ActionCell,
        cellRendererParams: {
          onLabourHourTargetChange,
        },
        cellStyle: {
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        },
        field: "action",
        headerClass: "centered-table-header",
        headerName: "Actions",
      },
    ]);

    const targetInfo = labourHourAnalytics?.targets || [];
    targetInfo.sort((a, b) => {
      const aType =
        a.targetDescription || labourHourCommitmentTypeToString(a.targetType);
      const bType =
        b.targetDescription || labourHourCommitmentTypeToString(b.targetType);
      return aType.localeCompare(bType);
    });
    return (
      <Table<LabourHourTargetAnalyticsLineItem>
        columnDefs={colDefs}
        loading={loading}
        data={targetInfo}
        searchKeys={["targetType", "targetDescription"]}
      />
    );
  }

  return <AnalyticsLabourHoursTable />;
};
