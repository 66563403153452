import { Box, Button, Grid } from "@mui/material";
import { SortInfo } from "@stories/atoms/TableHeader/TableHeader";
import SupportingDocumentDialog from "@stories/molecules/SupportingDocumentDialog/SupportingDocumentDialog";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { useCallback, useRef, useState } from "react";
import { DEFAULT_LABOUR_HOUR_LIMIT } from "social-pro-common/entities/labourHour";
import { LabourHourForTargetLineItem } from "social-pro-common/interfaces/labourHour";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

import { Table } from "../Table/Table";

interface AnalyticsHoursLevel3TableProps {
  loading: boolean;
  labourHours: LabourHourForTargetLineItem[];
  labourHourCount: number;
  isLoadingMore: boolean;
  sortInfo: SortInfo;
  handleRequestSort: (sortInfo: SortInfo) => void;
  viewEmployeeDetails: (arg: LabourHourForTargetLineItem) => void;
  hasMore: boolean;
  targetId: string;
  reportId: string;
  mode?: string;
}

interface ActionCellProps extends CustomCellRendererProps {
  viewEmployeeDetails: (arg: LabourHourForTargetLineItem) => void;
}

const ActionCell = (props: ActionCellProps) => {
  const { data, viewEmployeeDetails } = props;

  function handleClick() {
    viewEmployeeDetails(data);
  }

  return (
    <Button onClick={handleClick} variant="outlined" size="small">
      View
    </Button>
  );
};

export const AnalyticsHoursLevel3Table = ({
  handleRequestSort,
  isLoadingMore,
  labourHourCount,
  labourHours,
  loading,
  sortInfo,
  viewEmployeeDetails,
}: AnalyticsHoursLevel3TableProps) => {
  const observer = useRef<IntersectionObserver | null>(null);

  const [selectedLabourHour, setSelectedLabourHour] =
    useState<LabourHourForTargetLineItem>();

  const handleClose = () => {
    setSelectedLabourHour(undefined);
  };

  // Callback for observing the trigger element
  const lastElementRef = useCallback(
    (node: any) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          handleRequestSort({ ...sortInfo, page: sortInfo.page + 1 }); // Increment page on intersection
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, sortInfo],
  );
  function AnalyticsLabourHoursTable() {
    const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
      {
        field: "employeeName",
        flex: 3,
        headerName: "Employee ID",
      },
      {
        field: "organisationName",
        flex: 3,
        headerName: "Subcontractor",
      },
      {
        field: "primaryEmployer",
        flex: 3,
        headerName: "Employer",
      },
      {
        cellStyle: {
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }, // Center the button
        field: "previousHoursWorked",
        flex: 2,
        headerClass: "centered-table-header",
        headerName: "Previous Hours Worked",
        valueFormatter: (params) => `${formatDecimalPlaces(params.value, 0)}`,
      },
      {
        cellStyle: {
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }, // Center the button
        field: "currentHoursWorked",
        flex: 2,
        headerClass: "centered-table-header",
        headerName: "Current Hours Worked",
        valueFormatter: (params) => `${formatDecimalPlaces(params.value, 0)}`,
      },
      {
        cellStyle: {
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }, // Center the button
        field: "totalHoursWorked",
        flex: 2,
        headerClass: "centered-table-header",
        headerName: "Total Hours Worked",
        valueFormatter: (params) => `${formatDecimalPlaces(params.value, 0)}`,
      },
      {
        cellStyle: {
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }, // Center the button
        field: "totalHoursStudied",
        flex: 2,
        headerClass: "centered-table-header",
        headerName: "Total Hours Studied",
        valueFormatter: (params) => `${formatDecimalPlaces(params.value, 0)}`,
      },
      {
        cellRenderer: ActionCell,
        cellRendererParams: {
          viewEmployeeDetails,
        },
        cellStyle: {
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }, // Center the button
        field: "action",
        headerClass: "centered-table-header",
        headerName: "Actions",
      },
    ]);

    return (
      <Table<LabourHourForTargetLineItem>
        columnDefs={colDefs}
        data={labourHours}
        loading={loading}
        searchKeys={["organisationName", "employeeName"]}
      />
    );
  }

  return (
    <>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          margin: "0 4px",
          padding: "20px 0 !important",
        }}
      >
        <AnalyticsLabourHoursTable />
      </Grid>
      {labourHourCount > DEFAULT_LABOUR_HOUR_LIMIT &&
      labourHours.length < labourHourCount ? (
        <Box
          ref={lastElementRef}
          style={{ backgroundColor: "transparent", height: "20px" }}
        />
      ) : null}

      {selectedLabourHour ? (
        <SupportingDocumentDialog
          open
          handleClose={handleClose}
          labourHour={selectedLabourHour}
        />
      ) : null}
    </>
  );
};
