import { Avatar, Box, LinearProgress } from "@mui/material";
import { SearchFilter } from "@stories/organisms/SearchFilter/SearchFilter";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import { useState } from "react";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import CustomLoadingOverlay from "./CustomLoadingOverlay";

type TableProps<T extends object = any> = {
  columnDefs: (ColDef | ColGroupDef)[];
  data: T[];
  searchKeys?: (keyof T)[];
  loading: boolean;
};

export const ProgressCell = (props: CustomCellRendererProps) => {
  const { progress } = props.data;
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        height: "100%", // Ensures the container fills the cell height
        justifyContent: "space-around",
      }}
    >
      <span
        style={{
          fontWeight: 500,
          textAlign: "left", // Aligns text to the right
          width: "50px", // Fixed width for text ensures consistent layout
        }}
      >
        {formatDecimalPlaces(progress, 0)}%
      </span>
      <LinearProgress
        variant={"determinate"}
        value={progress}
        sx={{ flex: 1 }}
      />
    </Box>
  );
};

export function filterByKeys<T>(data: T[], keys: (keyof T)[]) {
  return function (value: string) {
    const formatted = value.trim().toLocaleLowerCase();

    if (formatted.length < 2) {
      return data;
    }

    return data.filter((item) => {
      return keys.some((key) => {
        return typeof item[key] === "string"
          ? (item[key] as string)
              .toLowerCase()
              .trim()
              .includes(formatted.toLowerCase())
          : false;
      });
    });
  };
}

export function Table<T extends object = any>({
  columnDefs,
  data,
  loading,
  searchKeys = [],
}: TableProps<T>) {
  const [searchValue, setSearchValue] = useState("");

  const searchFilter = filterByKeys(data, searchKeys);

  const rows = searchFilter(searchValue);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          paddingBottom: 2,
          width: "100%",
        }}
      >
        {searchKeys.length > 0 && (
          <Box>
            <SearchFilter
              onChange={(value: string) => setSearchValue(value)}
              loading={loading}
            />
          </Box>
        )}
      </Box>
      <Box className="ag-theme-quartz">
        <AgGridReact
          loading={loading}
          suppressServerSideFullWidthLoadingRow
          rowData={rows}
          columnDefs={columnDefs}
          defaultColDef={{
            flex: 1,
            resizable: true,
          }}
          domLayout="autoHeight"
          components={{
            customLoadingOverlay: CustomLoadingOverlay,
          }}
          loadingOverlayComponent={"customLoadingOverlay"}
          getRowClass={(params) => {
            return params.data.isTotalRow ? "total-row" : "";
          }}
        />
      </Box>
    </>
  );
}

export const AvatarNameCellRenderer = (props: CustomCellRendererProps) => {
  const { contractorLogo, contractorName } = props.data;
  return (
    <Box sx={{ alignItems: "center", display: "flex" }}>
      <Avatar
        src={contractorLogo}
        alt={contractorName}
        style={{
          backgroundColor: "#ffffff",
          height: 24,
          marginRight: "8px",
          width: 24,
        }}
        sizes="small"
      />
      <span>{contractorName}</span>
    </Box>
  );
};
