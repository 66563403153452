import { Button } from "@mui/material";
import { ProgressCell, Table } from "@stories/organisms/Table/Table";
import { ColDef, ColGroupDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { CustomCellRendererProps } from "ag-grid-react";
import { useState } from "react";
import {
  SocialSpendAnalyticsModelLineItem,
  SocialSpendTargetAnalyticsLineItem,
} from "social-pro-common/interfaces/analytics";
import { socialSpendCommitmentTypeToString } from "social-pro-common/interfaces/packageSocialSpendCommitment";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

interface AnalyticsSpendLevel1TableProps {
  loading: boolean;
  socialSpendAnalysis?: SocialSpendAnalyticsModelLineItem;
  onSocialSpendTargetChange: (targetId: string) => void;
}
interface ActionCellProps extends CustomCellRendererProps {
  onSocialSpendTargetChange: (arg: number) => void;
}

const ActionCell = (props: ActionCellProps) => {
  const {
    data: { id },
    onSocialSpendTargetChange,
  } = props;

  function handleClick() {
    onSocialSpendTargetChange(id);
  }

  return (
    <Button onClick={handleClick} variant="outlined" size="small">
      View
    </Button>
  );
};

export const AnalyticsSpendLevel1Table = ({
  loading,
  onSocialSpendTargetChange,
  socialSpendAnalysis,
}: AnalyticsSpendLevel1TableProps) => {
  function AnalyticsSpendTable() {
    const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
      {
        field: "targetType",
        flex: 3,
        headerName: "Target Type",
        valueGetter: (params: any) => {
          const description = params.data.targetDescription; // Default to empty if null/undefined
          const type = params.data.targetType
            ? socialSpendCommitmentTypeToString(params.data.targetType)
            : "";
          return description || type; // Prefer description, fallback to type
        },
      },
      {
        cellStyle: {
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }, // Center the button
        field: "targetValue",
        flex: 2,
        headerClass: "centered-table-header",
        headerName: "Target ($)",
        valueFormatter: (params) => `$${formatDecimalPlaces(params.value, 0)}`,
      },
      {
        cellStyle: {
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }, // Center the button
        field: "totalReportedValue",
        flex: 2,
        headerClass: "centered-table-header",
        headerName: "Spend to Date ($)",
        valueFormatter: (params) => `$${formatDecimalPlaces(params.value, 0)}`,
      },
      {
        cellStyle: {
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }, // Center the button
        field: "monthReportedValue",
        flex: 2,
        headerClass: "centered-table-header",
        headerName: "This Month",
        valueFormatter: (params) => `$${formatDecimalPlaces(params.value, 0)}`,
      },
      {
        cellRenderer: ProgressCell,
        field: "progress",
        flex: 2,
        headerClass: "centered-table-header",
        headerName: "Progress to Target",
      },
      {
        cellRenderer: ActionCell,
        cellRendererParams: {
          disabled: loading,
          onSocialSpendTargetChange,
        },
        cellStyle: {
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }, // Center the button
        field: "action",
        headerClass: "centered-table-header",
        headerName: "Actions",
      },
    ]);

    return (
      <Table<SocialSpendTargetAnalyticsLineItem>
        columnDefs={colDefs}
        loading={loading}
        data={socialSpendAnalysis?.targets || []}
        searchKeys={["targetType", "targetDescription"]}
      />
    );
  }

  return <AnalyticsSpendTable />;
};
