import { getApiData, listApiData, postApiData } from "@hooks/utils/api";
import { getImageUrl } from "@hooks/utils/useUpload";
import { catchSentryError } from "@utils/sentry";
import * as React from "react";
import { ToastOptions, toast } from "react-toastify";
import {
  decodeContractorPackage,
  decodeContractorPackageSetupLineItem,
  encodeContractorPackage,
  encodeOneTimeContractorPackage,
} from "social-pro-common/decoders/contractorPackage";
import {
  ContractorPackage,
  ContractorPackageSetup,
} from "social-pro-common/entities/contractorPackage";
import {
  ContractorPackageLineItem,
  ContractorPackageSetupLineItem,
} from "social-pro-common/interfaces/contractorPackage";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";

export const useContractorPackage = (projectId?: string) => {
  const [contractorPackages, setContractorPackages] = React.useState<
    ContractorPackageLineItem[]
  >([]);
  const [isContractorPackageLoading, setIsLoading] =
    React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);

  const handleApiError = (e: any, errorMessage: string) => {
    catchSentryError(e);
    setError(errorMessage);
    toast.error(errorMessage);
  };

  const fetchData = React.useCallback(
    async (apiFunction: string, id: string, errorMessage: string) => {
      try {
        setIsLoading(true);
        const result = await getApiData(apiFunction, "contractorPackage", id);
        if (!result.data || Object.keys(result.data).length === 0) {
          return undefined;
        }
        return decodeContractorPackage(
          result.data as ContractorPackage,
          getImageUrl,
        );
      } catch (e: any) {
        handleApiError(e, errorMessage);
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );

  const getContractorPackage = React.useCallback(
    async (id: string): Promise<ContractorPackageLineItem | undefined> => {
      return await fetchData(
        "getContractorPackage",
        id,
        "Could not fetch contractorPackage",
      );
    },
    [],
  );

  const getContractorPackageForUser = React.useCallback(
    async (id: string): Promise<ContractorPackageLineItem | undefined> => {
      return await fetchData(
        "getContractorPackageForUser",
        id,
        "Could not fetch contractorPackage",
      );
    },
    [],
  );

  const listContractorPackages = React.useCallback(
    async (projectId: string): Promise<void> => {
      try {
        setIsLoading(true);
        const result = await listApiData(
          "listContractorPackageDetails",
          "contractorPackage",
          projectId,
          {},
        );
        const packageLineItems = await Promise.all(
          result.data.map((p) =>
            decodeContractorPackage(p as ContractorPackage, getImageUrl),
          ),
        );
        setContractorPackages(packageLineItems);
      } catch (e: any) {
        handleApiError(e, "Could not list contractorPackages");
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );

  const listContractorPackagesForProject = React.useCallback(
    async (
      projectId: string,
      reportDate?: string,
    ): Promise<ContractorPackageLineItem[]> => {
      try {
        setIsLoading(true);
        const result = await listApiData(
          "listContractorPackages",
          "contractorPackage",
          projectId,
          { reportDate },
        );
        return Promise.all(
          result.data.map((p) =>
            decodeContractorPackage(p as ContractorPackage, getImageUrl),
          ),
        );
      } catch (e: any) {
        handleApiError(e, "Could not list contractorPackages");
        return [];
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );

  const searchContractorPackages = React.useCallback(
    async (query: string): Promise<ContractorPackageLineItem[]> => {
      try {
        const result = await listApiData(
          "searchContractorPackages",
          "contractorPackage",
          query,
        );
        return Promise.all(
          result.data.map((p) =>
            decodeContractorPackage(p as ContractorPackage, getImageUrl),
          ),
        );
      } catch (e: any) {
        handleApiError(e, "Could not search contractorPackages");
        return [];
      }
    },
    [],
  );

  const updateContractorPackage = React.useCallback(
    async (contractorPackage: ContractorPackageLineItem): Promise<void> => {
      try {
        setIsLoading(true);
        const encodedContractorPackage =
          encodeContractorPackage(contractorPackage);
        await postApiData(
          "updateContractorPackage",
          "contractorPackage",
          encodedContractorPackage,
        );
        setContractorPackages(
          contractorPackages.map((c) =>
            c.id === contractorPackage.id ? contractorPackage : c,
          ),
        );
        toast("Package updated.", { type: "success" } as ToastOptions);
      } catch (e: any) {
        handleApiError(e, "Could not update contractorPackage");
      } finally {
        setIsLoading(false);
      }
    },
    [contractorPackages],
  );

  const deleteContractorPackage = React.useCallback(
    async (contractorPackage: ContractorPackageLineItem): Promise<void> => {
      try {
        setIsLoading(true);
        const encodedContractorPackage =
          encodeContractorPackage(contractorPackage);
        await postApiData(
          "deleteContractorPackage",
          "contractorPackage",
          encodedContractorPackage,
        );
        setContractorPackages(
          contractorPackages.filter((p) => p.id !== contractorPackage.id),
        );
        toast("Package deleted.", { type: "success" } as ToastOptions);
      } catch (e: any) {
        handleApiError(e, "Could not delete contractorPackage");
      } finally {
        setIsLoading(false);
      }
    },
    [contractorPackages],
  );

  const resendInvite = React.useCallback(
    async (contractorPackageId: string) => {
      try {
        await getApiData(
          "resendInvite",
          "contractorPackage",
          contractorPackageId,
        );
        toast("Invite resent!", { type: "success" } as ToastOptions);
      } catch (e: any) {
        handleApiError(e, "Could not resend invite");
      }
    },
    [],
  );

  const getContractorPackageSetup = async (
    id: string,
  ): Promise<ContractorPackageSetupLineItem | undefined> => {
    try {
      setIsLoading(true);
      const contractorPackageResult = await getApiData(
        "getContractorPackageSetup",
        "contractorOneTimePackage",
        id,
      );
      if (
        !contractorPackageResult ||
        Object.keys(contractorPackageResult.data).length === 0
      ) {
        return undefined;
      }
      return decodeContractorPackageSetupLineItem(
        contractorPackageResult.data as ContractorPackageSetup,
        getImageUrl,
      );
    } catch (e: any) {
      setError("Could not fetch contractorPackage");
    } finally {
      setIsLoading(false);
    }
  };

  const createContractorPackageSetUp = async (
    organisation: OrganisationLineItem,
    contractorPackage: ContractorPackageLineItem,
    profile?: ProfileLineItem,
  ): Promise<ContractorPackageSetupLineItem> => {
    try {
      setIsLoading(true);
      const encodedContractorPackage = encodeOneTimeContractorPackage(
        organisation,
        contractorPackage,
        profile,
      );
      await postApiData(
        "createContractorPackage",
        "contractorPackage",
        encodedContractorPackage,
      );

      setContractorPackages([...contractorPackages, contractorPackage]);

      toast("Package created.", {
        type: "success",
      } as ToastOptions);
      return {
        contractorPackage,
        organisation,
        profile,
      };
    } catch (e: any) {
      toast("Could not create package!", {
        type: "error",
      } as ToastOptions);
      setError("Could not list contractorPackages");
    } finally {
      setIsLoading(false);
    }
    return {
      contractorPackage,
      organisation,
      profile,
    };
  };

  const updateContractorOneTimePackage = async (
    organisation: OrganisationLineItem,
    contractorPackage: ContractorPackageLineItem,
    profile?: ProfileLineItem,
  ): Promise<ContractorPackageSetupLineItem> => {
    try {
      setIsLoading(true);
      const encodedContractorPackage =
        encodeContractorPackage(contractorPackage);
      await postApiData(
        "updateContractorOneTimePackage",
        "contractorOneTimePackage",
        encodedContractorPackage,
      );
      setContractorPackages(
        contractorPackages.map((c) => {
          if (c.id === contractorPackage.id) {
            return contractorPackage;
          }
          return c;
        }),
      );
      toast("Package updated.", {
        type: "success",
      } as ToastOptions);
    } catch (e: any) {
      toast("Could not update package!", {
        type: "error",
      } as ToastOptions);
      setError("Could not update contractorPackage");
    } finally {
      setIsLoading(false);
    }
    return {
      contractorPackage,
      organisation,
      profile,
    };
  };

  React.useEffect(() => {
    if (projectId) {
      listContractorPackages(projectId);
    } else {
      setIsLoading(false);
    }
  }, [projectId]);

  return {
    contractorPackages,
    createContractorPackageSetUp,
    deleteContractorPackage,
    error,
    getContractorPackage,
    getContractorPackageForUser,
    getContractorPackageSetup,
    isContractorPackageLoading,
    listContractorPackages,
    listContractorPackagesForProject,
    resendInvite,
    searchContractorPackages,
    updateContractorOneTimePackage,
    updateContractorPackage,
  };
};
