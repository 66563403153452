import { getApiData } from "@hooks/utils/api";
import { getImageUrl } from "@hooks/utils/useUpload";
import { catchSentryError } from "@utils/sentry";
import { useCallback, useEffect, useState } from "react";
import {
  decodeLabourHoursAnalyticsForPackageModel,
  decodeLabourHoursAnalyticsModel,
} from "social-pro-common/decoders/analytics";
import { LabourHoursAnalyticsForPackageModel } from "social-pro-common/entities/analytics";
import { LabourHoursAnalyticsForPackageModelLineItem } from "social-pro-common/interfaces/analytics";

export const useLabourHourAnalyticsForPackages = (
  reportId: string,
  projectId?: string,
  contractorPackageId?: string,
  targetId?: string,
) => {
  const [isAnalysisLoading, setIsAnalysisLoading] = useState<boolean>(true);
  const [labourHoursAnalytics, setLabourHoursAnalytics] =
    useState<LabourHoursAnalyticsForPackageModelLineItem>();
  const [error, setError] = useState<string | null>(null);

  const getLabourHoursAnalyticsForPackageModel = useCallback(
    async (
      projectId: string,
      contractorPackageId: string,
      targetId: string,
      reportId: string,
    ): Promise<void> => {
      try {
        setIsAnalysisLoading(true);
        const analysisResult = await getApiData(
          "getSubcontractorLabourHourAnalytics",
          "analytics",
          projectId,
          { contractorPackageId, reportId, targetId },
        );

        const res = await decodeLabourHoursAnalyticsForPackageModel(
          analysisResult.data as LabourHoursAnalyticsForPackageModel,
          getImageUrl,
        );
        setLabourHoursAnalytics(res);
      } catch (e) {
        catchSentryError(e);
        setError("Could not fetch chart data");
      } finally {
        setIsAnalysisLoading(false);
      }
    },
    [decodeLabourHoursAnalyticsModel, getApiData],
  );

  useEffect(() => {
    if (projectId && contractorPackageId && targetId) {
      getLabourHoursAnalyticsForPackageModel(
        projectId,
        contractorPackageId,
        targetId,
        reportId,
      );
    }
  }, [
    reportId,
    projectId,
    contractorPackageId,
    targetId,
    getLabourHoursAnalyticsForPackageModel,
  ]);

  return {
    error,
    isAnalysisLoading,
    labourHoursAnalytics,
  };
};
