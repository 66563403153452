import { useProjectContext } from "@hooks/context/useProjectContext";
import { useLabourHourExport } from "@hooks/crud/downloadFiles/useLabourHourExport";
import { useLabourHoursForTargetByEmployee } from "@hooks/crud/labourHours/useLabourHoursForTargetByEmployee";
import DownloadIcon from "@mui/icons-material/Download";
import { Grid, Stack } from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { SortInfo } from "@stories/atoms/TableHeader/TableHeader";
import { PageHeader } from "@stories/molecules/PageHeader/PageHeader";
import { PdfDownlodDialog } from "@stories/molecules/PdfDownlodDialog/PdfDownlodDialog";
import { AnalyticsHoursLevel3Table } from "@stories/organisms/AnalyticsHoursTables/AnalyticsHoursLevel3Table";
import { Breadcrumbs } from "@stories/organisms/Breadcrumbs/Breadcrumbs";
import * as RouteHelper from "@utils/routes";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LabourHourForTargetLineItem } from "social-pro-common/interfaces/labourHour";
import { labourHourCommitmentTypeToString } from "social-pro-common/interfaces/packageLabourHourCommitment";
import { getMaximalDate } from "social-pro-common/utils/date";

export const AnalyticsHoursLevel3 = () => {
  const { mode, projectId, reportId, subPackageId, targetId } = useParams();

  if (!projectId) {
    throw Error("No project id");
  }
  if (!targetId) {
    throw Error("No target id");
  }
  if (!reportId) {
    throw Error("No report id");
  }
  if (!subPackageId) {
    throw Error("No sub package id");
  }

  const navigate = useNavigate();

  const [sortInfo, setSortInfo] = useState<SortInfo>({
    index: 0,
    order: "asc",
    orderBy: 0,
    page: 0,
  });

  const handleRequestSort = (newInfo: SortInfo) => {
    setSortInfo({
      ...sortInfo,
      ...newInfo,
    });
  };

  const {
    contractorPackage,
    isPrimaryPackage,
    isProjectLoading,
    selectedProject,
  } = useProjectContext();

  const { downloadProgress, downloadStep, getLabourHourExcel } =
    useLabourHourExport();

  const {
    hasMore,
    isLabourHourLoading,
    isLoadingMore,
    labourHourCount,
    labourHours,
  } = useLabourHoursForTargetByEmployee(
    selectedProject?.id,
    targetId,
    subPackageId,
    sortInfo,
    mode,
  );

  const loading = isProjectLoading || isLabourHourLoading;
  const selectedTarget = selectedProject?.commitmentsHours.find(
    (c) => c.id === targetId,
  );

  const currentDate = getMaximalDate(
    isPrimaryPackage
      ? selectedProject?.projectStartDate
      : contractorPackage?.packageStartDate,
    isPrimaryPackage
      ? selectedProject?.projectEndDate
      : contractorPackage?.packageEndDate,
  );
  const matchingTarget = selectedProject?.commitmentsHours.find(
    (target) => target.id === targetId,
  );
  const uniqueName = labourHours
    .map((t) => t.organisationName)
    .filter((item, index, self) => index === self.findIndex((t) => t === item));

  const displayText =
    mode == "all"
      ? "All"
      : uniqueName.length
        ? uniqueName[0]
        : "Employee Summary";

  const breadcrumbData = [
    {
      href: RouteHelper.analyticsLabourHours(
        selectedProject?.id || "",
        contractorPackage?.id || "",
        currentDate,
      ),
      label: "Labour Hours",
    },
    {
      href: RouteHelper.analyticsLabourHoursSubcontractors(
        selectedProject?.id || "asdadas",
        contractorPackage?.id || "",
        targetId,
        reportId,
      ),
      label: matchingTarget
        ? matchingTarget.targetDescription ||
          labourHourCommitmentTypeToString(matchingTarget.targetName)
        : "",
    },
    {
      href: RouteHelper.analyticsLabourHours(
        selectedProject?.id || "",
        contractorPackage?.id || "",
        currentDate,
      ),
      label: displayText,
    },
  ];

  const selectedProjectRef = useRef(selectedProject);
  const selectedPackageRef = useRef(contractorPackage);

  //Ag Table is not updating when the selectedProject changes
  //This is a workaround to make sure the table updates when the selectedProject changes
  useEffect(() => {
    selectedProjectRef.current = selectedProject;
    selectedPackageRef.current = contractorPackage;
  }, [selectedProject, contractorPackage]);

  const viewEmployeeDetails = useCallback(
    (labourHour: LabourHourForTargetLineItem) => {
      console.log("View employee details", labourHour);
      const currentProject = selectedProjectRef.current;
      const currentContractorPackage = selectedPackageRef.current;
      if (currentProject && currentContractorPackage) {
        navigate(
          RouteHelper.analyticsLabourHoursEmployees(
            currentProject.id,
            currentContractorPackage.id,
            subPackageId,
            reportId,
            targetId,
            labourHour.id,
          ),
        );
      }
    },
    [selectedProject, contractorPackage, navigate, targetId, reportId],
  );

  return (
    <>
      <Grid
        container
        sx={{
          margin: "0",
          marginBottom: "0",
          width: "100%",
        }}
      >
        <PageHeader
          loading={loading}
          title={`Labour Hours - ${
            selectedTarget
              ? selectedTarget.targetDescription ||
                labourHourCommitmentTypeToString(selectedTarget.targetName)
              : ""
          }`}
          subTitle={
            isPrimaryPackage ? "Project Analytics" : "Package Analytics"
          }
        />

        <Grid
          item
          md={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px 40px 10px",
          }}
        >
          <Breadcrumbs loading={loading} data={breadcrumbData} />
          <Stack
            direction="row"
            spacing={2}
            alignContent={"end"}
            justifyContent={"end"}
          >
            <StyledButton
              className="topBarBtn"
              loading={loading}
              disabled={loading || !labourHours || !labourHours.length}
              variant="contained"
              onClick={() => {
                if (projectId && subPackageId && reportId && targetId) {
                  getLabourHourExcel(projectId, targetId, subPackageId);
                }
              }}
              startIcon={<DownloadIcon />}
            >
              Export Records
            </StyledButton>
          </Stack>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        className="analytic-table glb-table-sty"
        sx={{ padding: "0px 40px !important" }}
      >
        <AnalyticsHoursLevel3Table
          loading={loading}
          targetId={targetId}
          reportId={reportId}
          labourHours={labourHours}
          labourHourCount={labourHourCount}
          viewEmployeeDetails={viewEmployeeDetails}
          handleRequestSort={handleRequestSort}
          isLoadingMore={isLoadingMore}
          sortInfo={sortInfo}
          hasMore={hasMore}
          mode={mode}
        />
      </Grid>
      <PdfDownlodDialog
        downloadStep={downloadStep}
        total={downloadProgress}
        title={"Labour Hours Export"}
      />
    </>
  );
};
