import { getApiData } from "@hooks/utils/api";
import { getImageUrl } from "@hooks/utils/useUpload";
import { catchSentryError } from "@utils/sentry";
import { useCallback, useEffect, useState } from "react";
import {
  decodeSocialSpendAnalyticsForPackageModel,
  decodeSocialSpendAnalyticsModel,
} from "social-pro-common/decoders/analytics";
import { SocialSpendAnalyticsForPackageModel } from "social-pro-common/entities/analytics";
import { SocialSpendAnalyticsForPackageModelLineItem } from "social-pro-common/interfaces/analytics";

export const useSocialSpendAnalyticsForPackages = (
  reportId: string,
  projectId?: string,
  contractorPackageId?: string,
  targetId?: string,
) => {
  const [isAnalysisLoading, setIsAnalysisLoading] = useState<boolean>(true);
  const [socialSpendAnalytics, setSocialSpendAnalytics] =
    useState<SocialSpendAnalyticsForPackageModelLineItem>();
  const [error, setError] = useState<string | null>(null);

  const getSocialSpendAnalyticsForPackageModel = useCallback(
    async (
      reportId: string,
      contractorPackageId: string,
      projectId: string,
      targetId: string,
    ): Promise<void> => {
      try {
        setIsAnalysisLoading(true);
        const analysisResult = await getApiData(
          "getSubcontractorSocialSpendAnalytics",
          "analytics",
          projectId,
          { contractorPackageId, reportId, targetId },
        );

        const res = await decodeSocialSpendAnalyticsForPackageModel(
          analysisResult.data as SocialSpendAnalyticsForPackageModel,
          getImageUrl,
        );
        setSocialSpendAnalytics(res);
      } catch (e) {
        catchSentryError(e);
        setError("Could not fetch chart data");
      } finally {
        setIsAnalysisLoading(false);
      }
    },
    [decodeSocialSpendAnalyticsModel, getApiData],
  );

  useEffect(() => {
    if (projectId && contractorPackageId && targetId) {
      getSocialSpendAnalyticsForPackageModel(
        reportId,
        projectId,
        contractorPackageId,
        targetId,
      );
    }
  }, [reportId, projectId, targetId, getSocialSpendAnalyticsForPackageModel]);

  return {
    error,
    isAnalysisLoading,
    socialSpendAnalytics,
  };
};
