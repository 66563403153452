import { useProjectContext } from "@hooks/context/useProjectContext";
import { useSustainabilityAnalytics } from "@hooks/crud/analytics/useSustainabilityAnalytics";
import { useContractorPackage } from "@hooks/crud/contractorPackages/useContractorPackages";
import { Grid } from "@mui/material";
import { ContractorAnalyticsEnvSustainability } from "@stories/molecules/ContractorAnalyticsEnvSustainability/ContractorAnalyticsEnviroSustainability";
import { PageHeader } from "@stories/molecules/PageHeader/PageHeader";
import { SustainabilityTypeTabs } from "@stories/molecules/SustainabilityTypeTabs/SustainabilityTypeTabs";
import * as RouteHelper from "@utils/routes";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { GreenMetricLineItem } from "social-pro-common/interfaces/greenMetric";

export const AnalyticsEnviroSustainabilityDetails = () => {
  const { packageId, reportId } = useParams();
  const navigate = useNavigate();

  const [greenMetric, setGreenMetric] = useState<GreenMetricLineItem>();

  const [subPackage, setSubPackage] = useState<ContractorPackageLineItem>();

  const {
    contractorPackage,
    isPrimaryPackage,
    isProjectLoading,
    selectedProject,
  } = useProjectContext();

  const { getContractorPackage, isContractorPackageLoading } =
    useContractorPackage();

  const { isAnalysisLoading, sustainabilityAnalytics } =
    useSustainabilityAnalytics(
      reportId || "",
      selectedProject?.id,
      subPackage?.id,
    );

  const handlOnBack = () => {
    navigate(
      RouteHelper.analyticsEnviroSustainability(
        selectedProject?.id || "",
        contractorPackage?.id || "",
        reportId || "",
      ),
    );
  };

  useEffect(() => {
    const fetchContractorPackage = async () => {
      if (packageId) {
        const sp = await getContractorPackage(packageId);
        if (sp) {
          setSubPackage(sp);
        }
      }
    };
    fetchContractorPackage();
  }, [packageId]);

  useEffect(() => {
    if (selectedProject) {
      setGreenMetric(selectedProject.greenMetrics.find((gm) => gm));
    }
  }, [selectedProject]);

  const loading =
    isProjectLoading || isContractorPackageLoading || isAnalysisLoading;
  return (
    <Grid
      container
      sx={{
        margin: "0",
        marginBottom: "0",
        width: "100%",
      }}
    >
      <PageHeader
        loading={loading}
        title={`Sustainability - ${
          subPackage ? subPackage.contractorName : ""
        }`}
        subTitle={isPrimaryPackage ? "Project Analytics" : "Package Analytics"}
        handlOnBack={handlOnBack}
      />
      <Grid item sx={{ margin: "0px", padding: "20px 40px !important" }}>
        <SustainabilityTypeTabs
          isLoading={loading}
          value={greenMetric}
          greenMetrics={selectedProject?.greenMetrics || []}
          setGreenMetric={setGreenMetric}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        className="analytic-table"
        sx={{ margin: "0px", padding: "20px 40px !important" }}
      >
        <ContractorAnalyticsEnvSustainability
          loading={loading}
          greenMetric={greenMetric}
          targets={
            greenMetric && sustainabilityAnalytics?.targets.length
              ? sustainabilityAnalytics?.targets.filter(
                  (t) => t.greenMetricId === greenMetric?.id,
                )
              : []
          }
        />
      </Grid>
    </Grid>
  );
};
