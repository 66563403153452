import { useProjectContext } from "@hooks/context/useProjectContext";
import { useContractorPackage } from "@hooks/crud/contractorPackages/useContractorPackages";
import { Grid } from "@mui/material";
import { PageHeader } from "@stories/molecules/PageHeader/PageHeader";
import { AnalyticsLocalContentLevel1Table } from "@stories/organisms/AnalyticsLocalContentTables/AnalyticsLocalContentLevel1Table";
import * as RouteHelper from "@utils/routes";
import { useNavigate, useParams } from "react-router-dom";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";

export const AnalyticsEnviroSustainability = () => {
  const { reportId } = useParams();

  const navigate = useNavigate();

  const {
    contractorPackage,
    isPrimaryPackage,
    isProjectLoading,
    selectedProject,
  } = useProjectContext();

  const { contractorPackages, isContractorPackageLoading } =
    useContractorPackage(selectedProject?.id);

  const loading = isProjectLoading || isContractorPackageLoading;
  return (
    <Grid
      container
      sx={{
        margin: "0",
        marginBottom: "0",
        width: "100%",
      }}
    >
      <PageHeader
        loading={loading}
        title="Sustainability"
        subTitle={isPrimaryPackage ? "Project Analytics" : "Package Analytics"}
      />
      <Grid
        item
        xs={12}
        md={12}
        className="analytic-table glb-table-sty"
        sx={{ padding: "30px 40px !important" }}
      >
        <AnalyticsLocalContentLevel1Table
          loading={loading}
          contractorPackages={contractorPackages}
          viewPackageDetails={(subPackage: ContractorPackageLineItem) => {
            if (selectedProject && contractorPackage) {
              navigate(
                RouteHelper.analyticsEnviroSustainabilityDetails(
                  selectedProject.id,
                  contractorPackage.id,
                  subPackage.id,
                  reportId || "",
                ),
              );
            }
          }}
        />
      </Grid>
    </Grid>
  );
};
