import { getApiData } from "@hooks/utils/api";
import { catchSentryError } from "@utils/sentry";
import { useCallback, useEffect, useState } from "react";
import {
  decodeLabourHoursAnalyticsForEmployeeForTargetModel,
  decodeLabourHoursAnalyticsModel,
} from "social-pro-common/decoders/analytics";
import { LabourHoursAnalyticsForEmployeeForTargetModel } from "social-pro-common/entities/analytics";
import { LabourHoursAnalyticsForEmployeeForTargetModelLineItem } from "social-pro-common/interfaces/analytics";

export const useLabourHourAnalyticsForEmployees = (
  projectId?: string,
  contractorPackageId?: string,
  targetId?: string,
  contractorEmployeeId?: string,
) => {
  const [isAnalysisLoading, setIsAnalysisLoading] = useState<boolean>(true);
  const [labourHoursAnalytics, setLabourHoursAnalytics] =
    useState<LabourHoursAnalyticsForEmployeeForTargetModelLineItem>();
  const [error, setError] = useState<string | null>(null);

  const getEmployeeLabourHourAnalytics = useCallback(
    async (
      projectId: string,
      contractorPackageId: string,
      targetId: string,
      contractorEmployeeId?: string,
    ): Promise<void> => {
      try {
        setIsAnalysisLoading(true);
        const analysisResult = await getApiData(
          "getEmployeeLabourHourAnalytics",
          "analytics",
          projectId,
          { contractorEmployeeId, contractorPackageId, targetId },
        );

        const res = decodeLabourHoursAnalyticsForEmployeeForTargetModel(
          analysisResult.data as LabourHoursAnalyticsForEmployeeForTargetModel,
        );
        setLabourHoursAnalytics(res);
      } catch (e) {
        catchSentryError(e);
        setError("Could not fetch chart data");
      } finally {
        setIsAnalysisLoading(false);
      }
    },
    [decodeLabourHoursAnalyticsModel, getApiData],
  );

  useEffect(() => {
    if (projectId && targetId && contractorPackageId) {
      getEmployeeLabourHourAnalytics(
        projectId,
        contractorPackageId,
        targetId,
        contractorEmployeeId,
      );
    }
  }, [
    contractorEmployeeId,
    projectId,
    contractorPackageId,
    targetId,
    getEmployeeLabourHourAnalytics,
  ]);

  return {
    error,
    isAnalysisLoading,
    labourHoursAnalytics,
  };
};
