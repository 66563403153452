import { downloadFile } from "@hooks/utils/useUpload";
import { Button, Chip } from "@mui/material";
import { Table } from "@stories/organisms/Table/Table";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { useState } from "react";
import {
  PolicyAnalyticsModelLineItem,
  PolicyTargetAnalyticLineItem,
} from "social-pro-common/interfaces/analytics";
import { policyTypeToString } from "social-pro-common/interfaces/projectPolicy";
import { formatDecimalPlaces } from "social-pro-common/utils/number";
interface ContractorAnalyticsPolicySubRowProps {
  loading: boolean;
  policyAnalytics?: PolicyAnalyticsModelLineItem;
}

interface ActionCellProps extends CustomCellRendererProps {
  onDownload: (uri: string, policyName: string) => void;
  isDownloading: boolean;
}

const ActionCell = (props: ActionCellProps) => {
  const { data, isDownloading, onDownload } = props;

  function handleClick() {
    onDownload(
      data.uri,
      data.policyDescription || policyTypeToString(data.policyType),
    );
  }

  return (
    <Button
      onClick={handleClick}
      variant="outlined"
      size="small"
      disabled={isDownloading || !data.uri}
    >
      Download
    </Button>
  );
};

interface PolicyLevelCellProps {
  data: PolicyTargetAnalyticLineItem;
}

const PolicyLevelCell = (props: PolicyLevelCellProps) => {
  const { data } = props;
  return data.isProjectLevelPolicy ? (
    <Chip label="Project Level Policy" />
  ) : null;
};

export const ContractorAnalyticsPolicyTable = ({
  loading,
  policyAnalytics,
}: ContractorAnalyticsPolicySubRowProps) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const onDownload = async (uri: string, policyName: string) => {
    setIsDownloading(true);
    await downloadFile(uri, policyName);
    setIsDownloading(false);
  };

  const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      field: "policyDescription",
      flex: 3,
      headerName: "Policy Name",
      valueGetter: (params) =>
        params.data.policyDescription ||
        policyTypeToString(params.data.policyType),
    },
    {
      cellRenderer: PolicyLevelCell,
      cellStyle: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      },
      field: "isProjectLevelPolicy",
      flex: 2,
      headerName: "",
    },
    {
      cellStyle: { textAlign: "center" },
      field: "expectedSubmissions",

      flex: 2,
      headerClass: "centered-table-header",
      headerName: "Expected Submissions",
    },
    {
      cellStyle: { textAlign: "center" },
      field: "totalSubmissions",
      flex: 2,
      headerClass: "centered-table-header",
      headerName: "Total Submitted",
    },
    {
      cellStyle: { textAlign: "center" },
      field: "progress",
      flex: 2,
      headerClass: "centered-table-header",
      headerName: "Progress",
      valueFormatter: (params) => `${formatDecimalPlaces(params.value, 0)}%`,
    },
    {
      cellRenderer: ActionCell,
      cellRendererParams: {
        isDownloading,
        onDownload,
      },
      cellStyle: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }, // Center the button
      field: "action",
      headerClass: "centered-table-header",
      headerName: "Actions",
    },
  ]);

  return (
    <Table<PolicyTargetAnalyticLineItem>
      columnDefs={colDefs}
      loading={loading}
      data={policyAnalytics?.targets || []}
      searchKeys={["policyDescription"]}
    />
  );
};
