import { useProjectContext } from "@hooks/context/useProjectContext";
import { useLabourHourAnalyticsForEmployees } from "@hooks/crud/analytics/useLabourHourAnalyticsForEmployees";
import DownloadIcon from "@mui/icons-material/Download";
import { Grid, Stack } from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { SortInfo } from "@stories/atoms/TableHeader/TableHeader";
import { PageHeader } from "@stories/molecules/PageHeader/PageHeader";
import { AnalyticsHoursLevel4Table } from "@stories/organisms/AnalyticsHoursTables/AnalyticsHoursLevel4Table";
import { Breadcrumbs } from "@stories/organisms/Breadcrumbs/Breadcrumbs";
import * as RouteHelper from "@utils/routes";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { labourHourCommitmentTypeToString } from "social-pro-common/interfaces/packageLabourHourCommitment";

export const AnalyticsHoursLevel4 = () => {
  const { contractorEmployeeId, projectId, reportId, subPackageId, targetId } =
    useParams();

  const navigate = useNavigate();
  if (!targetId) {
    throw Error("No target id");
  }
  if (!reportId) {
    throw Error("No report id");
  }
  if (!projectId) {
    throw Error("No project id");
  }
  if (!subPackageId) {
    throw Error("No sub contractor package id");
  }

  const [sortInfo, setSortInfo] = useState<SortInfo>({
    index: 0,
    order: "asc",
    orderBy: 0,
    page: 0,
  });

  const handleRequestSort = (newInfo: SortInfo) => {
    setSortInfo({
      ...sortInfo,
      ...newInfo,
    });
  };

  const {
    contractorPackage,
    isPrimaryPackage,
    isProjectLoading,
    selectedProject,
  } = useProjectContext();

  const { isAnalysisLoading, labourHoursAnalytics } =
    useLabourHourAnalyticsForEmployees(
      selectedProject?.id,
      subPackageId,
      targetId,
      contractorEmployeeId,
    );

  const loading = isProjectLoading || isAnalysisLoading;
  const selectedTarget = selectedProject?.commitmentsHours.find(
    (c) => c.id === targetId,
  );

  // const currentDate = getMaximalDate(
  //   isPrimaryPackage
  //     ? selectedProject?.projectStartDate
  //     : contractorPackage?.packageStartDate,
  //   isPrimaryPackage
  //     ? selectedProject?.projectEndDate
  //     : contractorPackage?.packageEndDate,
  // );
  const matchingTarget = selectedProject?.commitmentsHours.find(
    (target) => target.id === targetId,
  );

  const displayText = labourHoursAnalytics?.summary.employeeName || "";

  const breadcrumbData = [
    {
      href: RouteHelper.analyticsLabourHours(
        selectedProject?.id || "",
        contractorPackage?.id || "",
        reportId,
      ),
      label: "Labour Hours",
    },
    {
      href: RouteHelper.analyticsLabourHoursSubcontractors(
        selectedProject?.id || "asdadas",
        contractorPackage?.id || "",
        targetId,
        reportId,
      ),
      label: matchingTarget
        ? matchingTarget.targetDescription ||
          labourHourCommitmentTypeToString(matchingTarget.targetName)
        : "",
    },
    {
      href: RouteHelper.analyticsViewLabourHours(
        selectedProject?.id || "",
        contractorPackage?.id || "",
        reportId,
        targetId,
        "package",
        "",
      ),
      label: displayText,
    },
  ];

  return (
    <>
      <Grid
        container
        sx={{
          margin: "0",
          marginBottom: "0",
          width: "100%",
        }}
      >
        <PageHeader
          loading={loading}
          title={`Labour Hours - ${
            selectedTarget
              ? selectedTarget.targetDescription ||
                labourHourCommitmentTypeToString(selectedTarget.targetName)
              : ""
          }`}
          subTitle={
            isPrimaryPackage ? "Project Analytics" : "Package Analytics"
          }
        />

        <Grid
          item
          md={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px 40px 10px",
          }}
        >
          <Breadcrumbs loading={loading} data={breadcrumbData} />
          <Stack
            direction="row"
            spacing={2}
            alignContent={"end"}
            justifyContent={"end"}
          >
            <StyledButton
              className="topBarBtn"
              loading={loading}
              disabled={loading || !labourHoursAnalytics?.targets.length}
              variant="contained"
              onClick={() => {
                if (projectId && subPackageId && reportId && targetId) {
                  navigate(
                    RouteHelper.analyticsViewLabourHours(
                      selectedProject?.id || "",
                      contractorPackage?.id || "",
                      subPackageId,
                      reportId,
                      targetId,
                      "all",
                    ),
                  );
                }
              }}
              startIcon={<DownloadIcon />}
            >
              View All
            </StyledButton>
          </Stack>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        className="analytic-table glb-table-sty"
        sx={{ padding: "0px 40px !important" }}
      >
        <AnalyticsHoursLevel4Table
          loading={loading}
          targetId={targetId}
          reportId={reportId}
          labourHours={labourHoursAnalytics?.targets || []}
          handleRequestSort={handleRequestSort}
          sortInfo={sortInfo}
          labourHourCount={0}
          isLoadingMore={false}
          hasMore={false}
        />
      </Grid>
    </>
  );
};
