"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.decodeSustainabilitysAnalyticsModel = exports.decodeSustainabilityTargetAnalytics = exports.decodePolicyAnalyticsModel = exports.decodePolicyTargetAnalytics = exports.decodeSocialSpendAnalyticsForPackageModel = exports.decodeSocialSpendTargetAnalyticsForPackage = exports.decodeSocialSpendAnalyticsModel = exports.decodeSocialSpendTargetAnalytics = exports.decodeLabourHoursAnalyticsForEmployeeForTargetModel = exports.decodeLabourHoursAnalyticsForEmployeeLineItem = exports.decodeLabourHoursAnalyticsForEmployeeModel = exports.decodeLabourHoursAnalyticsForEmployee = exports.decodeLabourHoursAnalyticsForPackageModel = exports.decodeLabourHourTargetAnalyticsForPackage = exports.decodeLabourHoursAnalyticsModel = exports.decodeLabourHourTargetAnalytics = void 0;
const labourHour_1 = require("../labourHour");
const decodeLabourHourTargetAnalytics = (data) => {
    return {
        actual: data.actual,
        id: data.id,
        monthReportedValue: data.month_reported_value,
        progress: data.progress,
        targetDescription: data.target_description,
        targetType: data.target_type,
        targetValue: data.target_value,
        totalReportedValue: data.total_reported_value,
    };
};
exports.decodeLabourHourTargetAnalytics = decodeLabourHourTargetAnalytics;
const decodeLabourHoursAnalyticsModel = (data) => {
    return {
        targets: data.targets.map((target) => (0, exports.decodeLabourHourTargetAnalytics)(target)),
    };
};
exports.decodeLabourHoursAnalyticsModel = decodeLabourHoursAnalyticsModel;
const decodeLabourHourTargetAnalyticsForPackage = async (data, getImgUrl) => {
    const contractorLogo = await getImgUrl(data.contractor_logo);
    return {
        actual: data.actual,
        contractorLogo,
        contractorName: data.contractor_name,
        id: data.id,
        monthReportedValue: data.month_reported_value,
        packageNumber: data.package_number,
        progress: data.progress,
        targetValue: data.target_value,
        totalReportedValue: data.total_reported_value,
    };
};
exports.decodeLabourHourTargetAnalyticsForPackage = decodeLabourHourTargetAnalyticsForPackage;
const decodeLabourHoursAnalyticsForPackageModel = async (data, getImgUrl) => {
    const targets = await Promise.all(data.targets.map((target) => (0, exports.decodeLabourHourTargetAnalyticsForPackage)(target, getImgUrl)));
    const summary = (0, exports.decodeLabourHourTargetAnalytics)(data.summary);
    return {
        summary,
        targets,
    };
};
exports.decodeLabourHoursAnalyticsForPackageModel = decodeLabourHoursAnalyticsForPackageModel;
const decodeLabourHoursAnalyticsForEmployee = (data) => {
    return {
        employeeIdentifier: data.employee_identifier,
        employeeName: data.employee_name,
        id: data.id,
        primaryEmployer: data.primary_employer,
        subcontractor: data.subcontractor,
        totalHoursStudied: data.total_hours_studied,
        totalHoursWorked: data.total_hours_worked,
    };
};
exports.decodeLabourHoursAnalyticsForEmployee = decodeLabourHoursAnalyticsForEmployee;
const decodeLabourHoursAnalyticsForEmployeeModel = async (data, getImageUrl) => {
    const summary = await (0, exports.decodeLabourHourTargetAnalyticsForPackage)(data.summary, getImageUrl);
    return {
        summary,
        targets: data.targets.map((target) => (0, exports.decodeLabourHoursAnalyticsForEmployee)(target)),
    };
};
exports.decodeLabourHoursAnalyticsForEmployeeModel = decodeLabourHoursAnalyticsForEmployeeModel;
const decodeLabourHoursAnalyticsForEmployeeLineItem = (data) => {
    return {
        employeeIdentifier: data.employee_identifier,
        employeeName: data.employee_name,
        id: data.id,
        primaryEmployer: data.primary_employer,
        subcontractor: data.subcontractor,
        totalHoursStudied: data.total_hours_studied,
        totalHoursWorked: data.total_hours_worked,
    };
};
exports.decodeLabourHoursAnalyticsForEmployeeLineItem = decodeLabourHoursAnalyticsForEmployeeLineItem;
const decodeLabourHoursAnalyticsForEmployeeForTargetModel = (data) => {
    return {
        summary: (0, exports.decodeLabourHoursAnalyticsForEmployeeLineItem)(data.summary),
        targets: data.targets.map((target) => (0, labourHour_1.decodeLabourHourForTarget)(target)),
    };
};
exports.decodeLabourHoursAnalyticsForEmployeeForTargetModel = decodeLabourHoursAnalyticsForEmployeeForTargetModel;
const decodeSocialSpendTargetAnalytics = (data) => {
    return {
        id: data.id,
        monthReportedValue: data.month_reported_value,
        progress: data.progress,
        targetDescription: data.target_description,
        targetType: data.target_type,
        targetValue: data.target_value,
        totalReportedValue: data.total_reported_value,
    };
};
exports.decodeSocialSpendTargetAnalytics = decodeSocialSpendTargetAnalytics;
const decodeSocialSpendAnalyticsModel = (data) => {
    return {
        targets: data.targets.map((target) => (0, exports.decodeSocialSpendTargetAnalytics)(target)),
    };
};
exports.decodeSocialSpendAnalyticsModel = decodeSocialSpendAnalyticsModel;
const decodeSocialSpendTargetAnalyticsForPackage = async (data, getImgUrl) => {
    const contractorLogo = await getImgUrl(data.contractor_logo);
    return {
        contractorLogo,
        contractorName: data.contractor_name,
        id: data.id,
        monthReportedValue: data.month_reported_value,
        packageNumber: data.package_number,
        progress: data.progress,
        targetValue: data.target_value,
        totalReportedValue: data.total_reported_value,
    };
};
exports.decodeSocialSpendTargetAnalyticsForPackage = decodeSocialSpendTargetAnalyticsForPackage;
const decodeSocialSpendAnalyticsForPackageModel = async (data, getImgUrl) => {
    const targets = await Promise.all(data.targets.map((target) => (0, exports.decodeSocialSpendTargetAnalyticsForPackage)(target, getImgUrl)));
    const summary = (0, exports.decodeSocialSpendTargetAnalytics)(data.summary);
    return {
        summary,
        targets,
    };
};
exports.decodeSocialSpendAnalyticsForPackageModel = decodeSocialSpendAnalyticsForPackageModel;
const decodePolicyTargetAnalytics = (data) => {
    return {
        expectedSubmissions: data.expected_submissions,
        id: data.id,
        isProjectLevelPolicy: data.is_project_level_policy,
        policyDescription: data.policy_description,
        policyType: data.policy_type,
        progress: data.progress,
        totalSubmissions: data.total_submissions,
        uri: data.uri,
    };
};
exports.decodePolicyTargetAnalytics = decodePolicyTargetAnalytics;
const decodePolicyAnalyticsModel = (data) => {
    return {
        targets: data.targets.map((target) => (0, exports.decodePolicyTargetAnalytics)(target)),
    };
};
exports.decodePolicyAnalyticsModel = decodePolicyAnalyticsModel;
const decodeSustainabilityTargetAnalytics = (data) => {
    return {
        category: data.category,
        greenMetricId: data.green_metric_id,
        id: data.id,
        pcIncrease: data.pc_increase,
        thisMonth: data.this_month,
        toDate: data.to_date,
        type: data.type,
        uri: data.uri,
    };
};
exports.decodeSustainabilityTargetAnalytics = decodeSustainabilityTargetAnalytics;
const decodeSustainabilitysAnalyticsModel = (data) => {
    return {
        targets: data.targets.map((target) => (0, exports.decodeSustainabilityTargetAnalytics)(target)),
    };
};
exports.decodeSustainabilitysAnalyticsModel = decodeSustainabilitysAnalyticsModel;
