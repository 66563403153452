import { getApiData } from "@hooks/utils/api";
import { catchSentryError } from "@utils/sentry";
import { useCallback, useEffect, useState } from "react";
import { decodeSocialSpendTargetModel } from "social-pro-common/decoders/socialSpend";
import { SocialSpendTargetModel } from "social-pro-common/entities/socialSpend";
import { SocialSpendLineItem } from "social-pro-common/interfaces/socialSpend";

import { TableSortInfo } from "../labourHours/useLabourHoursForTarget";

export const useSocialSpendForTargets = (
  projectId?: string,
  targetId?: string,
  contractorPackageId?: string,
  sortInfo?: TableSortInfo,
  mode?: string,
) => {
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [socialSpendCount, setSocialSpendCount] = useState<number>(0);
  const [socialSpends, setSocialSpends] = useState<SocialSpendLineItem[]>([]);
  const [isSocialSpendLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const listSocialSpendForTarget = useCallback(
    async (
      projectId: string,
      targetId: string,
      contractorPackageId: string,
      sortInfo: TableSortInfo,
    ) => {
      try {
        setIsLoading(true);
        const socialSpendResult = await getApiData(
          "listSocialSpendForTarget",
          "socialSpend",
          projectId,
          {
            contractorPackageId,
            mode,
            page: sortInfo.page.toString(),
            sortColumn: sortInfo.orderBy,
            sortDirection: sortInfo.order,
            targetId,
          },
        );
        const socialSpendModel = decodeSocialSpendTargetModel(
          socialSpendResult.data as SocialSpendTargetModel,
        );

        setHasMore(socialSpendModel.hasMore);
        setSocialSpendCount(socialSpendModel.totalSocialSpend);
        setSocialSpends(socialSpendModel.socialSpend);
      } catch (e) {
        catchSentryError(e);
        setError("Could not list social spends");
      } finally {
        setIsLoading(false);
      }
    },
    [getApiData, decodeSocialSpendTargetModel, catchSentryError],
  );

  const fetchMoreSocialSpend = useCallback(
    async (
      projectId: string,
      targetId: string,
      contractorPackageId: string,
      sortInfo: TableSortInfo,
    ) => {
      try {
        setIsLoadingMore(true);
        const socialSpendResult = await getApiData(
          "listSocialSpendForTarget",
          "socialSpend",
          projectId,
          {
            contractorPackageId,
            page: sortInfo.page.toString(),
            sortColumn: sortInfo.orderBy,
            sortDirection: sortInfo.order,
            targetId,
          },
        );
        const socialSpendModel = decodeSocialSpendTargetModel(
          socialSpendResult.data as SocialSpendTargetModel,
        );
        setHasMore(socialSpendModel.hasMore);
        setSocialSpendCount(socialSpendModel.totalSocialSpend);
        setSocialSpends((prev) => [...prev, ...socialSpendModel.socialSpend]);
      } catch (error) {
        setError("Could not list labourHours");
      } finally {
        setIsLoadingMore(false);
      }
    },
    [
      getApiData,
      decodeSocialSpendTargetModel,
      socialSpends,
      projectId,
      targetId,
      contractorPackageId,
      sortInfo,
    ],
  );

  useEffect(() => {
    if (projectId && contractorPackageId && targetId && sortInfo) {
      if (sortInfo.page === 0) {
        listSocialSpendForTarget(
          projectId,
          targetId,
          contractorPackageId,
          sortInfo,
        );
      } else {
        fetchMoreSocialSpend(
          projectId,
          targetId,
          contractorPackageId,
          sortInfo,
        );
      }
    } else {
      setIsLoading(false);
    }
  }, [
    projectId,
    targetId,
    contractorPackageId,
    sortInfo,
    listSocialSpendForTarget,
  ]);

  return {
    error,
    hasMore,
    isLoadingMore,
    isSocialSpendLoading,
    listSocialSpendForTarget,
    socialSpendCount,
    socialSpends,
  };
};
