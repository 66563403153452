import { Box, LinearProgress, Typography } from "@mui/material";

const CustomLoadingOverlay = () => {
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <LinearProgress sx={{ mb: 2, width: "100%" }} />
      <Typography>Loading rows...</Typography>
    </Box>
  );
};

export default CustomLoadingOverlay;
