"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeContractorPackage = exports.decodeContractorPackage = exports.encodeOneTimeContractorPackage = exports.decodeContractorPackageSetupLineItem = void 0;
const string_1 = require("../../utils/string");
const financial_1 = require("../financial");
const greenMetricSubmission_1 = require("../greenMetricSubmission");
const organisation_1 = require("../organisation");
const packageLabourHourCommitment_1 = require("../packageLabourHourCommitment");
const packageLocalContent_1 = require("../packageLocalContent");
const packageSocialSpendCommitment_1 = require("../packageSocialSpendCommitment");
const policySubmission_1 = require("../policySubmission");
const profile_1 = require("../profile");
const decodeContractorPackageSetupLineItem = async (contractorPackage, getImageUrl) => {
    const org = await (0, organisation_1.decodeOrganisation)(contractorPackage.organisation, getImageUrl);
    const cp = await (0, exports.decodeContractorPackage)(contractorPackage.contractor_package, getImageUrl);
    return {
        contractorPackage: cp,
        organisation: org,
    };
};
exports.decodeContractorPackageSetupLineItem = decodeContractorPackageSetupLineItem;
const encodeOneTimeContractorPackage = (organisation, contractorPackage, profile) => {
    const org = (0, organisation_1.encodeOrganisation)(organisation);
    const cp = (0, exports.encodeContractorPackage)(contractorPackage);
    const prof = profile ? (0, profile_1.encodeProfile)(profile) : undefined;
    return {
        contractor_package: cp,
        organisation: org,
        profile: prof,
    };
};
exports.encodeOneTimeContractorPackage = encodeOneTimeContractorPackage;
const decodeContractorPackage = async (contractorPackage, getImageUrl) => {
    const presignedUrl = contractorPackage.organisation_logo &&
        contractorPackage.organisation_logo.length > 0
        ? await getImageUrl(contractorPackage.organisation_logo)
        : "";
    return {
        commitmentsHours: contractorPackage.commitments_hours.map((s) => (0, packageLabourHourCommitment_1.decodePackageLabourHourCommitment)(s)),
        commitmentsSpend: contractorPackage.commitments_spend.map((s) => (0, packageSocialSpendCommitment_1.decodePackageSocialSpendCommitment)(s)),
        contractorAbn: contractorPackage.abn,
        contractorId: contractorPackage.contractor_id,
        contractorLogo: presignedUrl,
        contractorName: contractorPackage.organisation_name,
        created: new Date(contractorPackage.created),
        estimatedProgress: contractorPackage.estimated_progress,
        financial: (0, financial_1.decodeFinancial)(contractorPackage.financial),
        greenMetricSubmissions: contractorPackage.green_metric_submissions.map((g) => (0, greenMetricSubmission_1.decodeGreenMetricSubmission)(g)),
        id: contractorPackage.contractor_package_id,
        isAccepted: contractorPackage.is_accepted,
        localContent: contractorPackage?.local_content?.map((lc) => (0, packageLocalContent_1.decodePackageLocalContent)(lc)) || [],
        nextReportId: contractorPackage.next_report_id,
        packageDescription: contractorPackage.package_description,
        packageEndDate: contractorPackage.package_end_date,
        packageNumber: contractorPackage.package_number,
        packageStartDate: contractorPackage.package_start_date,
        packageType: contractorPackage.package_type,
        policies: contractorPackage.policies?.map((p) => (0, policySubmission_1.decodePolicySubmission)(p)) || [],
        projectId: contractorPackage.project_id,
        representativeEmail: contractorPackage.representative_email,
        sendInvite: contractorPackage.send_invite,
        uri: contractorPackage.uri,
        useLabourHour: contractorPackage.use_labour_hour,
        useLocalContent: contractorPackage.use_local_content,
        usePolicies: contractorPackage.use_policy,
        useSocialSpend: contractorPackage.use_social_spend,
        useSustainability: contractorPackage.use_sustainability,
    };
};
exports.decodeContractorPackage = decodeContractorPackage;
const encodeContractorPackage = (contractorPackage) => {
    const sageContractorPackage = (0, string_1.makePostgresSafe)(contractorPackage);
    return {
        abn: sageContractorPackage.contractorAbn,
        commitments_hours: sageContractorPackage.commitmentsHours.map((s) => (0, packageLabourHourCommitment_1.encodePackageLabourHourCommitment)(s)),
        commitments_spend: sageContractorPackage.commitmentsSpend.map((s) => (0, packageSocialSpendCommitment_1.encodePackageSocialSpendCommitment)(s)),
        contractor_id: sageContractorPackage.contractorId,
        contractor_package_id: sageContractorPackage.id,
        created: sageContractorPackage.created,
        estimated_progress: sageContractorPackage.estimatedProgress,
        financial: (0, financial_1.encodeFinancial)(sageContractorPackage.financial),
        financial_id: sageContractorPackage.financial.id,
        green_metric_submissions: sageContractorPackage.greenMetricSubmissions.map((g) => (0, greenMetricSubmission_1.encodeGreenMetricSubmission)(g)),
        is_accepted: sageContractorPackage.isAccepted,
        local_content: sageContractorPackage.localContent?.map((lc) => (0, packageLocalContent_1.encodePackageLocalContent)(lc)) || [],
        next_report_id: sageContractorPackage.nextReportId,
        organisation_logo: sageContractorPackage.contractorLogo,
        organisation_name: sageContractorPackage.contractorName,
        package_description: sageContractorPackage.packageDescription,
        package_end_date: sageContractorPackage.packageEndDate,
        package_number: sageContractorPackage.packageNumber,
        package_start_date: sageContractorPackage.packageStartDate,
        package_type: sageContractorPackage.packageType,
        policies: sageContractorPackage.policies?.map((p) => (0, policySubmission_1.encodePolicySubmission)(p)) ||
            [],
        project_id: sageContractorPackage.projectId,
        representative_email: sageContractorPackage.representativeEmail,
        send_invite: sageContractorPackage.sendInvite,
        uri: contractorPackage.uri,
        use_labour_hour: sageContractorPackage.useLabourHour,
        use_local_content: sageContractorPackage.useLocalContent,
        use_policy: sageContractorPackage.usePolicies,
        use_social_spend: sageContractorPackage.useSocialSpend,
        use_sustainability: sageContractorPackage.useSustainability,
    };
};
exports.encodeContractorPackage = encodeContractorPackage;
