import { Button } from "@mui/material";
import { AvatarNameCellRenderer, Table } from "@stories/organisms/Table/Table";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { useState } from "react";
import {
  ContractorPackageLineItem,
  packageTypeToString,
} from "social-pro-common/interfaces/contractorPackage";

interface AnalyticsLocalContentLevel1TableProps {
  loading: boolean;
  contractorPackages: ContractorPackageLineItem[];
  viewPackageDetails: (contractorPackage: ContractorPackageLineItem) => void;
}

interface ActionCellProps extends CustomCellRendererProps {
  viewPackageDetails: (contractorPackage: ContractorPackageLineItem) => void;
}

const ActionCell = (props: ActionCellProps) => {
  const { data, viewPackageDetails } = props;

  function handleClick() {
    viewPackageDetails(data);
  }

  return (
    <Button onClick={handleClick} variant="outlined" size="small">
      View
    </Button>
  );
};

export const AnalyticsLocalContentLevel1Table = ({
  contractorPackages,
  loading,
  viewPackageDetails,
}: AnalyticsLocalContentLevel1TableProps) => {
  const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      field: "packageNumber",
      headerName: "No.",
      valueGetter: (params: any) => {
        return `${params.data.packageNumber}`;
      },
    },
    {
      cellRenderer: AvatarNameCellRenderer as any,
      field: "contractorName",
      flex: 3,
      headerName: "Subcontractor",
    },
    {
      field: "packageType",
      flex: 3,
      headerName: "Work Package",
      valueGetter: (params: any) => {
        return packageTypeToString(params.data.packageType);
      },
    },
    {
      cellStyle: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      },
      field: "packageStartDate",
      flex: 2,
      headerClass: "centered-table-header",
      headerName: "Start Date",
      valueGetter: (params: any) => {
        return new Date(params.data.packageStartDate).toLocaleDateString();
      },
    },
    {
      cellStyle: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      },

      field: "packageEndDate",
      flex: 2,
      headerClass: "centered-table-header",
      headerName: "Target Date",
      valueGetter: (params: any) => {
        return new Date(params.data.packageEndDate).toLocaleDateString();
      },
    },
    {
      cellRenderer: ActionCell,
      cellRendererParams: {
        viewPackageDetails,
      },
      cellStyle: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }, // Center the button
      field: "action",
      flex: 1,
      headerClass: "centered-table-header",
      headerName: "Action",
    },
  ]);

  return (
    <Table<ContractorPackageLineItem>
      columnDefs={colDefs}
      loading={loading}
      data={contractorPackages}
      searchKeys={["contractorName", "packageType"]}
    />
  );
};
