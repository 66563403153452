import DownloadIcon from "@mui/icons-material/Download";
import { Grid } from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { LidpTabs } from "@stories/molecules/LidpTabs/LidpTabs";
import { writeXlsxLidp } from "@utils/xlsx/localContent";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { useState } from "react";
import { PackageType } from "social-pro-common/entities/contractorPackage";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import {
  PackageLocalContentLineItem,
  aggregateLocalContentItems,
} from "social-pro-common/interfaces/packageLocalContent";
import {
  calculateLocalContentPackageAnzTotal,
  calculateLocalContentPackageContractTotal,
  calculateLocalContentPackageValueTotal,
} from "social-pro-common/utils/calc";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

import { Table } from "../Table/Table";

interface AnalyticsLocalContentLevel2TableProps {
  loading: boolean;
  isPrimaryPackage: boolean;
  contractorPackage?: ContractorPackageLineItem;
  localContents: PackageLocalContentLineItem[];
}

export const AnalyticsLocalContentLevel2Table = ({
  contractorPackage,
  isPrimaryPackage,
  loading,
  localContents,
}: AnalyticsLocalContentLevel2TableProps) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const showActual = value === 1;

  const itemsToDisplay = showActual
    ? localContents
    : aggregateLocalContentItems(contractorPackage?.localContent || []);

  const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      field: "itemDescription",
      flex: 3,
      headerName: "Description",
    },
    {
      field: "manufacturer",
      flex: 3,
      headerName: "Manufacturer",
    },
    {
      field: "supplier",
      flex: 2,
      headerName: "Supplier",
    },
    {
      cellStyle: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      },
      field: "value",
      headerClass: "centered-table-header",
      headerName: "Value ($)",
      valueFormatter: (params) => `$${formatDecimalPlaces(params.value, 0)}`,
    },
    {
      cellStyle: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      },
      field: "steelMass",
      headerClass: "centered-table-header",
      headerName: "Steel Mass (T)",
      valueFormatter: (params) =>
        params.value !== "-"
          ? `${formatDecimalPlaces(params.value)} T`
          : params.value,
    },
    {
      cellStyle: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      },
      field: "localValueAdd",
      headerClass: "centered-table-header",
      headerName: "Local Value Add",
      valueFormatter: (params) =>
        params.value !== "-"
          ? `${formatDecimalPlaces(params.value)} %`
          : params.value,
    },
    {
      cellStyle: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      },
      field: "importValueAdd",
      headerClass: "centered-table-header",
      headerName: "Import Value Add",
      valueFormatter: (params) =>
        params.value !== "-"
          ? `${formatDecimalPlaces(params.value)} %`
          : params.value,
    },
    {
      cellStyle: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      },
      field: "contractContent",
      headerClass: "centered-table-header",
      headerName: "Contract Content",
      valueFormatter: (params) => `${formatDecimalPlaces(params.value)}%`,
    },
    {
      cellStyle: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      },
      field: "anzValueAdd",
      headerClass: "centered-table-header",
      headerName: "ANZ value-add activity (%)",
      valueFormatter: (params) => `${formatDecimalPlaces(params.value, 4)}%`,
    },
    {
      cellStyle: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      },
      field: "smeCount",
      headerClass: "centered-table-header",
      headerName: "SME Count",
    },
    {
      cellStyle: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      },
      field: "supplierCount",
      headerClass: "centered-table-header",
      headerName: "Supplier Count",
    },
  ]);

  const contractTotal =
    calculateLocalContentPackageContractTotal(itemsToDisplay);

  const anzTotalTotal = calculateLocalContentPackageAnzTotal(itemsToDisplay);

  const valueTotal = calculateLocalContentPackageValueTotal(itemsToDisplay);

  const totalSme = itemsToDisplay.reduce(
    (acc, lc) => acc + (lc.smeCount ?? 0),
    0,
  );
  const totalSuppliers = itemsToDisplay.reduce(
    (acc, lc) => acc + (lc.supplierCount ?? 0),
    0,
  );

  const totalRow = {
    anzValueAdd: anzTotalTotal,
    contractContent: contractTotal,
    importValueAdd: "-",
    isTotalRow: true,
    itemDescription: "",
    localValueAdd: "-",
    smeCount: totalSme,
    steelMass: "-",
    supplier: "Totals",
    supplierCount: totalSuppliers,
    value: valueTotal,
  };

  return (
    <>
      <Grid
        container
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          padding: "20px 40px 10px",
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{ marginTop: "0", padding: "0 !important" }}
        >
          {isPrimaryPackage &&
          contractorPackage &&
          contractorPackage.packageType == PackageType.PrimaryPackage ? (
            <LidpTabs
              isLoading={loading}
              value={value}
              handleChange={handleChange}
            />
          ) : null}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "0",
            padding: "0 !important",
          }}
        >
          <StyledButton
            data-test-id="export-targets-button"
            className="topBarBtn"
            loading={loading}
            disabled={loading || !itemsToDisplay || !itemsToDisplay.length}
            variant="contained"
            onClick={() => {
              if (itemsToDisplay) {
                writeXlsxLidp(itemsToDisplay);
              }
            }}
            startIcon={<DownloadIcon />}
          >
            Export Targets
          </StyledButton>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        className="analytic-table glb-table-sty"
        sx={{ padding: "30px 40px !important" }}
      >
        <Table<PackageLocalContentLineItem>
          columnDefs={colDefs}
          loading={loading}
          data={[
            ...itemsToDisplay,
            totalRow as unknown as PackageLocalContentLineItem,
          ]}
          searchKeys={["itemDescription", "manufacturer", "supplier"]}
        />
      </Grid>
    </>
  );
};
