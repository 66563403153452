"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.decodePostCodeModel = exports.decodeSubcontractorActivity = exports.decodeSustainabilitySubDataModel = exports.decodeLocalContentSubDataModel = exports.decodeLocalContentRowDataModel = exports.decodeSocialSpendSubDataModel = exports.decodeLabourHourSubDataModel = exports.deocodeRecommendedSupplierModel = exports.decodeForecastDataModel = exports.decodeContractorSubDataModel = exports.decodeSustainabilityProgressModel = exports.decodeLocalContentProgressModel = exports.decodeSpendProgressModel = exports.decodeLabourHourProgressModel = exports.decodeWidgetModel = exports.decodeProjectProgress = exports.decodeDashboardModel = void 0;
const socialBusiness_1 = require("../socialBusiness");
const decodeDashboardModel = async (data, getImageUrl) => {
    const subcontractorActivity = data.subcontractor_activity
        ? await Promise.all(data.subcontractor_activity.map((sa) => (0, exports.decodeSubcontractorActivity)(sa, getImageUrl)))
        : [];
    return {
        labourHourProgress: data?.labour_hour_progress
            ? (0, exports.decodeLabourHourProgressModel)(data.labour_hour_progress) || undefined
            : undefined,
        localContentProgress: data?.local_content_progress
            ? (0, exports.decodeLocalContentProgressModel)(data.local_content_progress) ||
                undefined
            : undefined,
        projectProgress: (0, exports.decodeProjectProgress)(data.project_progress),
        spendProgress: data?.spend_progress
            ? (0, exports.decodeSpendProgressModel)(data.spend_progress) || undefined
            : undefined,
        subcontractorActivity,
        sustainabilityProgress: data?.sustainability_progress
            ? (0, exports.decodeSustainabilityProgressModel)(data.sustainability_progress) ||
                undefined
            : undefined,
        widgets: data?.widgets.map((widget) => (0, exports.decodeWidgetModel)(widget)) || [],
    };
};
exports.decodeDashboardModel = decodeDashboardModel;
const decodeProjectProgress = (data) => {
    return {
        activeSubcontractors: data.active_subcontractors,
        completedWorks: data.completed_works,
        timeProgress: data.time_progress,
    };
};
exports.decodeProjectProgress = decodeProjectProgress;
const decodeWidgetModel = (data) => {
    return {
        colour: data.colour,
        pcChange: data.pc_change,
        title: data.title,
        tooltip: data.tooltip,
        units: data.units,
        value: data.value,
    };
};
exports.decodeWidgetModel = decodeWidgetModel;
const decodeLabourHourProgressModel = (data) => {
    return {
        pcChange: data.pc_change,
        pcComplete: data.pc_complete,
        targetHours: data.target_hours,
        totalHours: data.total_hours,
    };
};
exports.decodeLabourHourProgressModel = decodeLabourHourProgressModel;
const decodeSpendProgressModel = (data) => {
    return {
        achievedSpend: data.achieved_spend,
        pcChange: data.pc_change,
        pcComplete: data.pc_complete,
        targetSpend: data.target_spend,
    };
};
exports.decodeSpendProgressModel = decodeSpendProgressModel;
const decodeLocalContentProgressModel = (data) => {
    return {
        anzValueAdd: data.anz_value_add,
        pcChange: data.pc_change,
        targetAnzValue: data.target_anz_value,
        valueReportedToDate: data.value_reported_to_date,
    };
};
exports.decodeLocalContentProgressModel = decodeLocalContentProgressModel;
const decodeSustainabilityProgressModel = (data) => {
    return {
        fuelsTotal: data.fuels_total,
        wasteTotal: data.waste_total,
        waterTotal: data.water_total,
    };
};
exports.decodeSustainabilityProgressModel = decodeSustainabilityProgressModel;
const decodeContractorSubDataModel = (data) => {
    return {
        pcChange: data.pc_change,
        secondaryValue: data.secondary_value,
        title: data.title,
        units: data.units,
        value: data.value,
    };
};
exports.decodeContractorSubDataModel = decodeContractorSubDataModel;
const decodeForecastDataModel = (data) => {
    return {
        forecastData: data.forecast_data.map((item) => (0, exports.decodeContractorSubDataModel)(item)),
        forecastTotal: data.forecast_total,
        pcAchieved: data.pc_achieved,
    };
};
exports.decodeForecastDataModel = decodeForecastDataModel;
const deocodeRecommendedSupplierModel = async (data, getImageUrl) => {
    const supplierLogo = await (0, socialBusiness_1.getSocialBusinessLogo)(data.supplier_logo, getImageUrl);
    return {
        supplierLogo: supplierLogo,
        supplierName: data.supplier_name,
        supplierTargetType: data.supplier_target_type,
    };
};
exports.deocodeRecommendedSupplierModel = deocodeRecommendedSupplierModel;
const decodeLabourHourSubDataModel = async (data, getImageUrl) => {
    const recommendedSuppliers = data.recommendedSuppliers
        ? await Promise.all(data.recommendedSuppliers.map((item) => (0, exports.deocodeRecommendedSupplierModel)(item, getImageUrl)))
        : undefined;
    return {
        forecast: (0, exports.decodeForecastDataModel)(data.forecast),
        recommendedSuppliers,
        subcontractors: data.subcontractors.map((item) => (0, exports.decodeContractorSubDataModel)(item)),
        targets: data.targets.map((item) => (0, exports.decodeContractorSubDataModel)(item)),
    };
};
exports.decodeLabourHourSubDataModel = decodeLabourHourSubDataModel;
const decodeSocialSpendSubDataModel = async (data, getImageUrl) => {
    const recommendedSuppliers = data.recommendedSuppliers
        ? await Promise.all(data.recommendedSuppliers.map((item) => (0, exports.deocodeRecommendedSupplierModel)(item, getImageUrl)))
        : undefined;
    return {
        forecast: (0, exports.decodeForecastDataModel)(data.forecast),
        recommendedSuppliers,
        socialSpendData: data.socialSpendData.map((item) => (0, exports.decodeContractorSubDataModel)(item)),
        targets: data.targets.map((item) => (0, exports.decodeContractorSubDataModel)(item)),
    };
};
exports.decodeSocialSpendSubDataModel = decodeSocialSpendSubDataModel;
const decodeLocalContentRowDataModel = (data) => {
    return {
        localValueAdd: data.local_value_add,
        target: data.target,
        title: data.title,
        value: data.value,
    };
};
exports.decodeLocalContentRowDataModel = decodeLocalContentRowDataModel;
const decodeLocalContentSubDataModel = (data) => {
    return {
        localContentTargets: data.local_content_targets.map((item) => (0, exports.decodeLocalContentRowDataModel)(item)),
        subcontractors: data.subcontractors.map((item) => (0, exports.decodeContractorSubDataModel)(item)),
    };
};
exports.decodeLocalContentSubDataModel = decodeLocalContentSubDataModel;
const decodeSustainabilitySubDataModel = (data) => {
    return {
        fuels: data.fuels.map((item) => (0, exports.decodeContractorSubDataModel)(item)),
        waste: data.waste.map((item) => (0, exports.decodeContractorSubDataModel)(item)),
        water: data.water.map((item) => (0, exports.decodeContractorSubDataModel)(item)),
    };
};
exports.decodeSustainabilitySubDataModel = decodeSustainabilitySubDataModel;
const decodeSubcontractorActivity = async (data, getImageUrl) => {
    const presignedUrl = data.organisation_logo.length > 0
        ? await getImageUrl(data.organisation_logo)
        : "";
    return {
        created: new Date(data.created),
        organisationLogo: presignedUrl,
        organisationName: data.organisation_name,
        reportId: data.report_id,
    };
};
exports.decodeSubcontractorActivity = decodeSubcontractorActivity;
const decodePostCodeModel = (data) => {
    return {
        postCode: data.post_code,
        value: data.value,
    };
};
exports.decodePostCodeModel = decodePostCodeModel;
