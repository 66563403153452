import { Button } from "@mui/material";
import {
  AvatarNameCellRenderer,
  ProgressCell,
  Table,
} from "@stories/organisms/Table/Table";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { useState } from "react";
import {
  SocialSpendAnalyticsForPackageModelLineItem,
  SocialSpendTargetAnalyticsForPackageLineItem,
} from "social-pro-common/interfaces/analytics";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

import SocialSpendAnalyticsSummary from "../Summary/SocialSpendAnalyticsSummary";

interface AnalyticsSpendTableLevel2TableProps {
  loading: boolean;
  targetId: string;
  socialSpendAnalytics?: SocialSpendAnalyticsForPackageModelLineItem;
  viewPackageDetails: (contractorPackage: ContractorPackageLineItem) => void;
}

interface ActionCellProps extends CustomCellRendererProps {
  disabled: boolean;
  viewPackageDetails: (contractorPackage: ContractorPackageLineItem) => void;
}

const ActionCell = (props: ActionCellProps) => {
  const { data, viewPackageDetails } = props;

  function handleClick() {
    viewPackageDetails(data);
  }

  return (
    <Button onClick={handleClick} variant="outlined" size="small">
      View
    </Button>
  );
};

export const AnalyticsSpendTableLevel2Table = ({
  loading,
  socialSpendAnalytics,
  viewPackageDetails,
}: AnalyticsSpendTableLevel2TableProps) => {
  const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      cellRenderer: AvatarNameCellRenderer as any,
      field: "contractorName",
      flex: 3,
      headerName: "Subcontractor",
    },
    {
      cellStyle: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      },
      field: "targetValue",
      flex: 2,
      headerClass: "centered-table-header",
      headerName: "Target ($)",
      valueFormatter: (params) => `$${formatDecimalPlaces(params.value, 0)}`,
    },
    {
      cellStyle: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      },
      field: "totalReportedValue",
      flex: 2,
      headerClass: "centered-table-header",
      headerName: "Spend to Date ($)",
      valueFormatter: (params) => `$${formatDecimalPlaces(params.value, 0)}`,
    },
    {
      cellStyle: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      },
      field: "monthReportedValue",
      flex: 2,
      headerClass: "centered-table-header",
      headerName: "This Month",
      valueFormatter: (params) => `$${formatDecimalPlaces(params.value, 0)}`,
    },
    {
      cellRenderer: ProgressCell,
      field: "action",
      flex: 2,
      headerClass: "centered-table-header",
      headerName: "Progress",
    },
    {
      cellRenderer: ActionCell,
      cellRendererParams: {
        disabled: loading,
        viewPackageDetails,
      },
      cellStyle: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      },
      headerClass: "centered-table-header",
      headerName: "Action",
    },
  ]);

  return (
    <>
      <SocialSpendAnalyticsSummary
        data={socialSpendAnalytics?.summary}
        loading={loading}
      />
      <Table<SocialSpendTargetAnalyticsForPackageLineItem>
        columnDefs={colDefs}
        loading={loading}
        data={socialSpendAnalytics?.targets || []}
        searchKeys={["contractorName"]}
      />
    </>
  );
};
