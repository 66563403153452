"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeLabourHour = exports.decodeLabourHourForTarget = exports.decodeLabourHour = exports.decodeEmployeeHoursToDate = exports.decodeLabourHourTargetModel = void 0;
const string_1 = require("../../utils/string");
const supportingDocument_1 = require("../supportingDocument");
const decodeLabourHourTargetModel = (labourHourModel) => {
    return {
        hasMore: labourHourModel.has_more,
        labourHours: labourHourModel.labour_hours.map((e) => (0, exports.decodeLabourHourForTarget)(e)),
        totalLabourHours: labourHourModel.total_labour_hours,
    };
};
exports.decodeLabourHourTargetModel = decodeLabourHourTargetModel;
const decodeEmployeeHoursToDate = (employeHourToDate) => {
    return {
        hoursStudied: employeHourToDate.hours_studied,
        hoursWorked: employeHourToDate.hours_worked,
        id: employeHourToDate.id,
        uri: employeHourToDate.uri,
    };
};
exports.decodeEmployeeHoursToDate = decodeEmployeeHoursToDate;
const decodeLabourHour = (labourHour) => {
    return {
        amountOfHoursStudied: labourHour.amount_of_hours_studied,
        amountOfHoursWorked: labourHour.amount_of_hours_worked,
        contractorPackageId: labourHour.contractor_package_id,
        created: new Date(labourHour.created),
        employeeId: labourHour.contractor_employee_id,
        employeeName: labourHour.employee_name,
        employmentLevel: labourHour.employment_level,
        gender: labourHour.gender,
        id: labourHour.labour_hour_id,
        organisationName: labourHour.organisation_name,
        projectId: labourHour.project_id,
        projectName: labourHour.project_name,
        reportId: labourHour.report_id,
        socialFactors: labourHour.social_factors,
        supportingDocuments: labourHour.supporting_documents
            ? labourHour.supporting_documents.map((d) => (0, supportingDocument_1.decodeSupportingDocument)(d))
            : [],
        uri: labourHour.uri,
    };
};
exports.decodeLabourHour = decodeLabourHour;
const decodeLabourHourForTarget = (labourHour) => {
    return {
        currentHoursStudied: labourHour.current_hours_studied,
        currentHoursWorked: labourHour.current_hours_worked,
        employeeIdentifier: labourHour.employee_identifier,
        employeeName: labourHour.employee_name,
        id: labourHour.contractor_employee_id,
        organisationName: labourHour.organisation_name,
        previousHoursWorked: labourHour.previous_hours_worked,
        primaryEmployer: labourHour.primary_employer,
        reportId: labourHour.report_id,
        totalHoursStudied: labourHour.total_hours_studied,
        totalHoursWorked: labourHour.total_hours_worked,
    };
};
exports.decodeLabourHourForTarget = decodeLabourHourForTarget;
const encodeLabourHour = (labourHour) => {
    const safeLabourHour = (0, string_1.makePostgresSafe)(labourHour);
    return {
        amount_of_hours_studied: safeLabourHour.amountOfHoursStudied,
        amount_of_hours_worked: safeLabourHour.amountOfHoursWorked,
        contractor_employee_id: safeLabourHour.employeeId,
        contractor_package_id: safeLabourHour.contractorPackageId,
        created: safeLabourHour.created,
        employee_name: safeLabourHour.employeeName,
        employment_level: safeLabourHour.employmentLevel,
        gender: safeLabourHour.gender,
        hours_to_date: 0,
        labour_hour_id: safeLabourHour.id,
        organisation_name: safeLabourHour.organisationName,
        project_id: safeLabourHour.projectId,
        project_name: safeLabourHour.projectName,
        report_id: safeLabourHour.reportId,
        social_factors: safeLabourHour.socialFactors,
        supporting_documents: safeLabourHour.supportingDocuments
            ? safeLabourHour.supportingDocuments.map((d) => (0, supportingDocument_1.encodeSupportingDocument)(d))
            : [],
        uri: safeLabourHour.uri,
    };
};
exports.encodeLabourHour = encodeLabourHour;
