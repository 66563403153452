import { Button } from "@mui/material";
import {
  AvatarNameCellRenderer,
  ProgressCell,
  Table,
} from "@stories/organisms/Table/Table";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { forwardRef, useImperativeHandle, useState } from "react";
import {
  LabourHoursAnalyticsForPackageModelLineItem,
  LabourHourTargetAnalyticsForPackageLineItem,
} from "social-pro-common/interfaces/analytics";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

import LabourHourAnalyticsSummary from "../Summary/LabourHourAnalyticsSummary";

interface AnalyticsHoursLevel2TableProps {
  loading: boolean;
  targetId: string;
  reportId: string;
  labourHoursAnalytics?: LabourHoursAnalyticsForPackageModelLineItem;
  viewPackageDetails: (contractorPackage: ContractorPackageLineItem) => void;
}

interface ActionCellProps extends CustomCellRendererProps {
  viewPackageDetails: (contractorPackage: ContractorPackageLineItem) => void;
}

const ActionCell = forwardRef((props: ActionCellProps, ref) => {
  const { data, viewPackageDetails } = props;

  useImperativeHandle(ref, () => ({
    refresh(_: any) {
      // Update if the disabled state changes
      return true;
    },
  }));

  function handleClick() {
    viewPackageDetails(data);
  }
  return (
    <Button onClick={handleClick} variant="outlined" size="small">
      View
    </Button>
  );
});

ActionCell.displayName = "ActionCell";

export const AnalyticsHoursLevel2Table = ({
  labourHoursAnalytics,
  loading,
  viewPackageDetails,
}: AnalyticsHoursLevel2TableProps) => {
  const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      cellRenderer: AvatarNameCellRenderer as any,
      field: "contractorName",
      flex: 3,
      headerName: "Subcontractor",
    },
    {
      cellStyle: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }, // Center the button
      field: "targetValue",
      flex: 2,
      headerClass: "centered-table-header",
      headerName: "Target (Hours)",
    },
    {
      cellStyle: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }, // Center the button
      field: "totalReportedValue",
      flex: 2,
      headerClass: "centered-table-header",
      headerName: "Hours to Date (Hours)",
      valueFormatter: (params) => `${formatDecimalPlaces(params.value, 0)}`,
    },
    {
      cellStyle: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }, // Center the button
      field: "monthReportedValue",
      flex: 2,
      headerClass: "centered-table-header",
      headerName: "This Month (Hours)",
    },
    {
      cellRenderer: ProgressCell,
      field: "action",
      flex: 2,
      headerClass: "centered-table-header",
      headerName: "Progress",
    },
    {
      cellRenderer: ActionCell,
      cellRendererParams: {
        viewPackageDetails,
      },
      cellStyle: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }, // Center the button
      field: "action",
      headerClass: "centered-table-header",
      headerName: "Action",
    },
  ]);

  return (
    <>
      <LabourHourAnalyticsSummary
        data={labourHoursAnalytics}
        loading={loading}
      />
      <Table<LabourHourTargetAnalyticsForPackageLineItem>
        columnDefs={colDefs}
        loading={loading}
        data={labourHoursAnalytics?.targets || []}
        searchKeys={["contractorName"]}
      />
    </>
  );
};
