import { useProjectContext } from "@hooks/context/useProjectContext";
import { useContractorPackage } from "@hooks/crud/contractorPackages/useContractorPackages";
import { Grid } from "@mui/material";
import { PageHeader } from "@stories/molecules/PageHeader/PageHeader";
import { AnalyticsLocalContentLevel1Table } from "@stories/organisms/AnalyticsLocalContentTables/AnalyticsLocalContentLevel1Table";
import * as RouteHelper from "@utils/routes";
import { useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";

export const AnalyticsLocalContentLevel1 = () => {
  const navigate = useNavigate();

  const {
    contractorPackage,
    isPrimaryPackage,
    isProjectLoading,
    selectedProject,
  } = useProjectContext();

  const { contractorPackages, isContractorPackageLoading } =
    useContractorPackage(selectedProject?.id);

  const loading = isProjectLoading || isContractorPackageLoading;

  const sortedData = contractorPackages.sort((a, b) => {
    return a.packageNumber.localeCompare(b.packageNumber);
  });

  const selectedProjectRef = useRef(selectedProject);
  const selectedPackageRef = useRef(contractorPackage);

  //Ag Table is not updating when the selectedProject changes
  //This is a workaround to make sure the table updates when the selectedProject changes
  useEffect(() => {
    selectedProjectRef.current = selectedProject;
    selectedPackageRef.current = contractorPackage;
  }, [selectedProject, contractorPackage]);

  const viewSubContractorDetails = useCallback(
    (subPackage: ContractorPackageLineItem) => {
      console.log("View package details", subPackage);
      const currentProject = selectedProjectRef.current;
      const currentContractorPackage = selectedPackageRef.current;
      if (currentProject && currentContractorPackage) {
        navigate(
          RouteHelper.analyticsLocalContentDetails(
            currentProject.id,
            currentContractorPackage.id,
            subPackage.id,
          ),
        );
      }
    },
    [selectedProject, contractorPackage, navigate],
  );

  return (
    <Grid
      container
      sx={{
        margin: "0",
        marginBottom: "0",
        width: "100%",
      }}
    >
      <PageHeader
        loading={loading}
        title="Local Content"
        subTitle={isPrimaryPackage ? "Project Analytics" : "Package Analytics"}
      />
      <Grid
        item
        xs={12}
        md={12}
        className="contract-table"
        sx={{
          margin: "0 40px",
          padding: "5px 0 40px !important",
        }}
      >
        <AnalyticsLocalContentLevel1Table
          loading={loading}
          contractorPackages={sortedData}
          viewPackageDetails={viewSubContractorDetails}
        />
      </Grid>
    </Grid>
  );
};
