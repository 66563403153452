"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.decodeSustainabilitySubDataModel = exports.decodeOrganisationLocalContentSubDataModel = exports.decodeOrganisationSocialSpendSubDataModel = exports.decodeOrganisationLabourHourSubDataModel = exports.decodeOrganisationSubDataModel = exports.decodeOrganisationProgress = exports.decodeOrganisationDashboardModel = void 0;
const dashboard_1 = require("../dashboard");
const decodeOrganisationDashboardModel = async (data, getImageUrl) => {
    const subcontractorActivity = data.subcontractor_activity
        ? await Promise.all(data.subcontractor_activity.map((sa) => (0, dashboard_1.decodeSubcontractorActivity)(sa, getImageUrl)))
        : [];
    return {
        labourHourProgress: data?.labour_hour_progress
            ? (0, dashboard_1.decodeLabourHourProgressModel)(data.labour_hour_progress) || undefined
            : undefined,
        localContentProgress: data?.local_content_progress
            ? (0, dashboard_1.decodeLocalContentProgressModel)(data.local_content_progress) ||
                undefined
            : undefined,
        organisationProgress: (0, exports.decodeOrganisationProgress)(data.organisation_progress),
        spendProgress: data?.spend_progress
            ? (0, dashboard_1.decodeSpendProgressModel)(data.spend_progress) || undefined
            : undefined,
        subcontractorActivity,
        sustainabilityProgress: data?.sustainability_progress
            ? (0, dashboard_1.decodeSustainabilityProgressModel)(data.sustainability_progress) ||
                undefined
            : undefined,
        widgets: data?.widgets.map((widget) => (0, dashboard_1.decodeWidgetModel)(widget)) || [],
    };
};
exports.decodeOrganisationDashboardModel = decodeOrganisationDashboardModel;
const decodeOrganisationProgress = (data) => {
    return {
        activeEmployees: data.active_employees,
        activeProjects: data.active_projects,
        totalValue: data.total_value,
    };
};
exports.decodeOrganisationProgress = decodeOrganisationProgress;
const decodeOrganisationSubDataModel = (data) => {
    return {
        primaryValue: data.primary_value,
        secondaryValue: data.secondary_value,
        title: data.title,
    };
};
exports.decodeOrganisationSubDataModel = decodeOrganisationSubDataModel;
const decodeOrganisationLabourHourSubDataModel = (data) => {
    return {
        projects: data.projects.map((item) => (0, exports.decodeOrganisationSubDataModel)(item)),
        targets: data.targets.map((item) => (0, exports.decodeOrganisationSubDataModel)(item)),
    };
};
exports.decodeOrganisationLabourHourSubDataModel = decodeOrganisationLabourHourSubDataModel;
const decodeOrganisationSocialSpendSubDataModel = (data) => {
    return {
        projects: data.projects.map((item) => (0, exports.decodeOrganisationSubDataModel)(item)),
        targets: data.targets.map((item) => (0, exports.decodeOrganisationSubDataModel)(item)),
    };
};
exports.decodeOrganisationSocialSpendSubDataModel = decodeOrganisationSocialSpendSubDataModel;
const decodeOrganisationLocalContentSubDataModel = (data) => {
    return {
        localContentTargets: data.local_content_targets.map((item) => (0, dashboard_1.decodeLocalContentRowDataModel)(item)),
        projects: data.projects.map((item) => (0, exports.decodeOrganisationSubDataModel)(item)),
    };
};
exports.decodeOrganisationLocalContentSubDataModel = decodeOrganisationLocalContentSubDataModel;
const decodeSustainabilitySubDataModel = (data) => {
    return {
        fuels: data.fuels.map((item) => (0, dashboard_1.decodeContractorSubDataModel)(item)),
        waste: data.waste.map((item) => (0, dashboard_1.decodeContractorSubDataModel)(item)),
        water: data.water.map((item) => (0, dashboard_1.decodeContractorSubDataModel)(item)),
    };
};
exports.decodeSustainabilitySubDataModel = decodeSustainabilitySubDataModel;
