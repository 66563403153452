import { Table } from "@stories/organisms/Table/Table";
import { ColDef, ColGroupDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { CustomCellRendererProps } from "ag-grid-react";
import { useState } from "react";

interface DataItem {
  action: string;
  collaborators: string;
  headContractor: string;
  id: number;
  location: string;
  progress: string;
  projectName: string;
  progressCell?: boolean;
}

interface ActionCellProps extends CustomCellRendererProps {
  setDataIndex: (arg: number) => void;
}

const data: DataItem[][] = [
  [
    {
      action: "action",
      collaborators: "-",
      headContractor: "Tegridy",
      id: 0,
      location: "1 Main st 3000",
      progress: "0%",
      progressCell: true,
      projectName: "Pothole creation",
    },
    {
      action: "action",
      collaborators: "-",
      headContractor: "Tegridy Farms",
      id: 1,
      location: "2 Main st 3000",
      progress: "10%",
      projectName: "Rubble",
    },
    {
      action: "action",
      collaborators: "-",
      headContractor: "Acme",
      id: 2,
      location: "4 Main st 3000",
      progress: "33%",
      projectName: "Maximum concrete",
    },
  ],
  [
    {
      action: "action",
      collaborators: "-",
      headContractor: "Tegridy1",
      id: 0,
      location: "1 High st 4000",
      progress: "20%",
      projectName: "Some Project",
    },
    {
      action: "action",
      collaborators: "-",
      headContractor: "Tegridy Farms1",
      id: 1,
      location: "2 South st 3666",
      progress: "45%",
      projectName: "Only the Finest Projects",
    },
    {
      action: "action",
      collaborators: "-",
      headContractor: "Acme1",
      id: 2,
      location: "54 Flinders st 3000",
      progress: "65%",
      projectName: "A huge hole",
    },
  ],
  [
    {
      action: "action",
      collaborators: "-",
      headContractor: "Tegridy2",
      id: 0,
      location: "1 Avenue av. 3000",
      progress: "3%",
      projectName: "Cave Dwellings",
    },
    {
      action: "action",
      collaborators: "-",
      headContractor: "Tegridy Farms2",
      id: 1,
      location: "Under a bridge 2000",
      progress: "99%",
      projectName: "Tent City",
    },
    {
      action: "action",
      collaborators: "-",
      headContractor: "Acme2",
      id: 2,
      location: "1 Silo Way 3014",
      progress: "85%",
      projectName: "Missile Silo",
    },
  ],
];

const ActionCell = (props: ActionCellProps) => {
  const {
    data: { id, progressCell },
    setDataIndex,
  } = props;

  function handleClick() {
    setDataIndex(id);
  }

  if (progressCell) {
    return <span>some data based custom render</span>;
  }

  return <button onClick={handleClick}>View</button>;
};

function LabourHours() {
  const [dataIndex, setDataIndex] = useState(0);

  const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    { field: "projectName", headerName: "Project Name" },
    { field: "headContractor", headerName: "Head Contractor" },
    { field: "location", headerName: "Location" },
    { field: "collaborators", headerName: "Collaborators" },
    { field: "progress", headerName: "Progress" },
    {
      cellRenderer: ActionCell,
      cellRendererParams: {
        setDataIndex,
      },
      field: "action",
      headerName: "Action",
    },
  ]);

  return (
    <Table<DataItem>
      columnDefs={colDefs}
      loading={false}
      data={data[dataIndex]}
      searchKeys={["projectName", "location", "headContractor"]}
    />
  );
}

export const AgTableView = () => {
  return <LabourHours />;
};
